.pageContainer {
  /* Можно добавить общую анимацию для страницы */
  animation: fadeIn 0.8s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Анимации */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Применяем классы к элементам для анимации */
.fadeInDown {
  animation: fadeInDown 1s ease-in-out forwards;
}

.fadeInUp {
  animation: fadeInUp 1s ease-in-out forwards;
}

.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out forwards;
}
