* {
    padding: 0;
    border: 0;
    margin: 0;
    box-sizing: border-box;
    display: block;
    outline: none;

    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

head, script {
    display: none;
}

a, span, sub, sup, b, strong, i, em, s, q {
    display: inline;
}

li {
    display: list-item;
    margin-left: 20px;
}

table {
    display: table;
}

thead {
    display: table-header-group;
}

tbody {
    display: table-row-group;
}

tfoot {
    display: table-footer-group;
}

tr {
    display: table-row;
}

td, th {
    display: table-cell;
}

caption {
    display: table-caption;
}

col {
    display: table-column;
}

colgroup {
    display: table-column-group;
}