.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}
.table th {
  font-weight: bold;
  padding: 5px;
  color: rgba(0, 0, 0, 0.88);
  background: #efefef;
  border: 1px solid #dddddd;
}
.table td {
  border: 1px solid #dddddd;
  padding: 5px;
}
.table tr td:first-child,
.table tr th:first-child {
  border-left: none;
}
.table tr td:last-child,
.table tr th:last-child {
  border-right: none;
}
.para {
  text-indent: 20px;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {

}
ul {
  line-height: 27px;
  margin-bottom: 20px;
}
li {
  font-size: 18px; 
}
.heading {
  color: aqua;
}

strong {
  font-size: 18px; 
}

.tableDescription tr td:last-child,
.tableDescription tr th:last-child {
  width: 120px;
}
.tableDescription tr td:nth-last-child(2),
.tableDescription tr th:nth-last-child(2) {
  width: 120px;
}

.link {
  font-size: 18px;
  color: #1677ff;
}

.link:hover{
  color: #e37021;
}

.ul {
  /* margin-left: 20px;  */
  margin-bottom: 20px; 
  line-height: 1.6; 
}

.li {
  /* list-style-type: disc;  */
  position: relative;
  padding-left: 5px;
  font-size: 18px;
  margin-bottom: 10px;
}
.li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 18px;
  height: 18px;
  background-image: url(../../img/check-icon.svg);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


@media (max-width: 500px) {
  .tableDescription tr td:nth-last-child(2),
  .tableDescription tr th:nth-last-child(2) {
    width: inherit;
  }
}
