.modal-window__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-window {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%; 
  box-sizing: border-box; 
}

.modal-window__content {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
  white-space: pre-wrap;
  overflow-y: auto;
  line-height: 1.5;
}

.modal-window__button {
  display: block;
  width: 100%;
  background: #0d4cd3;
  color: #fff;
  border: none;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-family: "LatoWebBold", sans-serif;
  font-size: 16px;
}
.modal-window__button:hover {
  background: #0252ff;
}
.modal-window__button:disabled {
  background: gray ;
  cursor: default;
}
.modal-window__button_close{
  background: none;
  border:1px solid #0d4cd3;
  color: #0d4cd3;
}
.modal-window__button_close:hover {
  opacity: inherit;
  background:#eee;
}

.modal-window p strong {
  font-weight: bold; 
}
.modal-window__title{
  font-weight: bold; 
  text-align: center;
  font-size: 1.3rem;
}
.modal-window__email{
  font-weight: bold; 
  text-align: center;
  font-size: 1.3rem;
}


@media (max-width: 768px) {
  .modal-window {
    padding: 15px; 
    max-width: 90%;
  }

  .modal-window__content {
    height: auto;
    max-height: 50vh;
  }

  .modal-window__button {
    padding: 10px; 
  }
}
