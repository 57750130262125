.modal__feedback__background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000; /* Увеличенный z-index */
}

.modal__feedback__content {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "LatoWeb", sans-serif;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 20px;
  position: relative;
  z-index: 10001; /* Увеличенный z-index */
}

/* Остальные стили остаются без изменений */


/* .modal__feedback__background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998 !important;
}

.modal__feedback__content {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "LatoWeb", sans-serif;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 20px;
  position: relative;
} */

.textarea__feedback,
.input__feedback {
  width: 100%;
  max-height: 80px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "LatoWeb", sans-serif;
}

.input-wrapper {
  margin-bottom: 1rem; 
}

.desc-text {
  font-size: 0.8rem; 
  color: #6c757d; 
  margin-top: -15px; 
  margin-left: 8px;
  display: block; 
}

.input__description{
  color: #6c757d; 
}

.required::after {
  content: "*"; 
  color: red; 
  margin-left: 0.25rem;
}

.textarea__feedback {
  height: 150px;
}

.photoReminder {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 20px;
}

.feedback__select {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-family: "LatoWeb", sans-serif;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.feedback__select:hover {
  border-color: #1d5deb;
  background-color: #f0f0f0;
}

.a__feedback__up{
  background: none;
  color: #0d4cd3;
}

.feedback__button {
  display: block;
  width: 100%;
  background: #0d4cd3;
  color: #fff;
  border: none;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-family: "LatoWebBold", sans-serif;
  font-size: 16px;
}
.feedback__button:hover {
  background: #0252ff;
}
.feedback__button:disabled {
  background: gray;
  cursor: default;
}
.feedback__button_close {
  background: none;
  border: 1px solid #0d4cd3;
  color: #0d4cd3;
}
.feedback__button_close:hover {
  opacity: inherit;
  background: #eee;
}

.feedback__close__button {
  display: block;
  width: 100%;
  background: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-family: "LatoWebBold", sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.feedback__close__button:hover {
  background: #e0e0e0;
}

.checkboxContainer__feedback {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
}

.input__feedback[type="checkbox"] {
  margin-right: 10px;
  align-self: start;
  width: 20px;
  height: 20px;
}

.checkboxText__feedback {
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  max-width: 90%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}
.dopinformation {
  line-height: 1.5;
  text-indent: 1rem;
}

.datetime__container {
  display: flex;
  gap: 8px;
}

.input__description {
  /* font-size: 0.8em; */
  color: #666;
}


.input__date,
.input__time {
  flex-basis: 50%;
}

.dopinformation__six__form{
  text-align: justify;
}


@media (max-width: 768px) {
  .modal__feedback__content {
    margin: 9px;
    max-width: 90%;
    padding: 10px;
    max-height: 80vh;
  }

  .disclaimer__feedback,
  .checkboxText__feedback {
    font-size:11px;
  }

  .checkboxContainer__feedback {
    flex-direction: column;
    align-items: flex-start;
  }

  .photoInstruction {
    font-size: 13px;
    padding: 0 10px;
  }
}
