@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.button {
  /* border: 1px solid gray; */
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: inline;
  background-color: #f56200;
  color: #fff;
  transition: all 0.1s ease-in-out;
}
.button:hover {
  background-color: #ff8533;
}
.button:active {
  transform: scale(0.95);
}
