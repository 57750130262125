a,
body,
button,
cite,
input,
li,
p,
select,
span,
textarea {
  font-weight: 400;
  font-size: 14px;
  color: #3a4558;
}

a,
body,
button,
cite,
h1,
h2,
h3,
h4,
input,
li,
p,
select,
span,
sup,
textarea {
  font-family: "Arial", sans-serif;
}

input {
  min-width: 1px;
}

body,
html {
  overflow-x: hidden;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p {
  margin: 0;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: inherit;
}

a:focus {
  color: inherit;
  text-decoration: none;
}

li,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  outline: inherit;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.container {
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  max-width: 94.2%;
  margin: 0 auto;
}

@media screen and (max-width: 1360px) {
  .container {
    max-width: 96.3%;
  }
}

@media screen and (max-width: 1023px) {
  .container {
    max-width: 97%;
  }
}

.hiden {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .hiden {
    padding-right: 17px;
  }
}

@media (max-width: 767px) {
  a,
  body,
  button,
  cite,
  input,
  li,
  p,
  select,
  span,
  textarea {
    font-weight: 400;
    font-size: 14px;
    color: #3a4558;
  }
}

@font-face {
  font-family: "Oswald";
  src: url(../fonts/Oswald/Oswald-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url(../fonts/Oswald/Oswald-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

.logo {
  max-width: 272px;
}

.logo--inner-mob {
  display: none;
}

.social-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0 -4px;
}

.social-list__item {
  padding: 0 4px;
}

.social-list__link,
.social-list__link::before {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background-color: #0061aa;
}

.social-list__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.social-list__link::before,
.title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}

.social-list__link::before {
  top: 0;
  right: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-list__link:hover::before {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  background-color: #e37021;
}

.social-list__icon {
  position: relative;
  z-index: 1;
}

.up-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding-bottom: 4px;
  margin-bottom: 40px;
}

.title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  position: relative;
  padding-bottom: 32px;
}

.title::before {
  width: 40px;
  height: 4px;
  background-color: #e37021;
}

.link-more,
.link-row {
  border: 1px solid #d8d6ca;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 11px 15px;
}

.link-more--bg {
  position: relative;
}

.link-more--bg::before,
.link-more__close {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.link-more--bg::before {
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #e37021;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
}

.link-more--bg .link-more__icon,
.link-more--bg .link-more__svg,
.link-more--bg .link-more__text,
.link-more--bg .link-row__text {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-more--bg:hover::before {
  left: -4px;
  top: -4px;
  bottom: -4px;
  right: -4px;
  opacity: 1;
}

.link-more--bg:hover .link-more__text,
.link-more--bg:hover .link-row__text {
  color: #fff;
}

.link-more--bg:hover .link-more__icon,
.link-more--bg:hover .link-more__svg,
.link-more:hover .link-more__wrap-check svg,
.link-row:hover .link-more__wrap-check svg {
  stroke: #fff;
}

.link-more__close {
  position: relative;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 12px;
}

.field__date .field.has-value .custom-button-content span::after,
.field__date .field.has-value .custom-button-content span::before,
.link-more__close::after,
.link-more__close::before {
  content: "";
  position: absolute;
  top: 4px;
  right: -webkit-calc(50% - 0.5px);
  right: -moz-calc(50% - 0.5px);
  right: calc(50% - 0.5px);
  bottom: 4px;
  width: 2px;
  background-color: #e37021;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.field__date .field.has-value .custom-button-content span::after,
.link-more__close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.link-more:hover .link-more__wrap-svg,
.link-more:hover .link-row__wrap-arrow {
  width: 48px;
  margin-right: -34px;
}

.link-more:hover .link-more__close::after,
.link-more:hover .link-more__close::before,
.link-row:hover .link-more__close::after,
.link-row:hover .link-more__close::before {
  background-color: #fff;
}

.link-more__text,
.link-row__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
}

.link-more__wrap-svg {
  width: 14px;
  margin-left: 15px;
  position: relative;
}

.link-more__wrap-svg,
.link-row__wrap-arrow {
  min-width: 14px;
  height: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-more__arrow {
  width: 48px;
}

.link-more__arrow,
.link-row__arrow {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 16px;
  height: 10px;
  stroke: #e37021;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-more__icon {
  width: 16px;
  height: 16px;
  stroke: #e37021;
  margin-left: 8px;
}

.link-more__wrap-check {
  margin-left: 14px;
}

.link-more__wrap-check svg {
  stroke: #e37021;
  width: 20px;
  height: 20px;
}

.link-more--center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.post-news__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.post-news__wrapper--change {
  grid-template-columns: 424px 1fr;
}

@media (max-width: 1500px) {
  .post-news__wrapper--change {
    grid-template-columns: 304px 1fr;
  }
}

.post-news__wrapper--block {
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.post-news__wrap-img {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.post-news__wrap-img--gallery,
.post-news__wrap-img--video {
  position: relative;
}

.post-news__wrap-img--video::before {
  content: "";
  position: absolute;
  width: 56px;
  height: 56px;
  left: -webkit-calc(50% - 28px);
  left: -moz-calc(50% - 28px);
  left: calc(50% - 28px);
  top: -webkit-calc(50% - 28px);
  top: -moz-calc(50% - 28px);
  top: calc(50% - 28px);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: rgba(227, 112, 33, 0.8);
  background-position: center;
  /* background-image: url(../img/play.svg); */
  background-repeat: no-repeat;
  z-index: 1;
  opacity: 0;
}

.custom-select__up:hover .custom-select__arrow path.line-arrow-animation,
.post-news__wrap-img--gallery:hover::before,
.post-news__wrap-img--video:hover::before {
  opacity: 1;
}

.post-news__category span,
.post-news__wrap-img--gallery::before,
.post-news__wrap-img--video::before {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.post-news__wrap-img--gallery::before {
  content: "";
  position: absolute;
  width: 56px;
  height: 56px;
  left: -webkit-calc(50% - 28px);
  left: -moz-calc(50% - 28px);
  left: calc(50% - 28px);
  top: -webkit-calc(50% - 28px);
  top: -moz-calc(50% - 28px);
  top: calc(50% - 28px);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: rgba(227, 112, 33, 0.8);
  background-position: center;
  /* background-image: url(../img/gallery-icon.svg); */
  background-repeat: no-repeat;
  z-index: 1;
  opacity: 0;
}

.post-news__wrap-img:hover .post-news__img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.post-news__img {
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.post-news__category,
.post-news__wrap-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.post-news__wrap-text {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.post-news__category {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding-bottom: 16px;
  margin-bottom: 14px;
  cursor: pointer;
}

.post-news__category img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.post-news__category span {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.post-news__date {
  font-size: 12px;
  color: #3a4558;
  opacity: 0.7;
  line-height: 24px;
  display: inline-block;
  margin-right: 16px;
}

.post-news__caption {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #3a4558;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 18px;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.post-news:hover .post-news__category span,
.post-news__caption:hover {
  color: #0061aa;
}

.post-news__caption::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 4px;
  background-color: #e37021;
}

.post-news__text {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
}

.tags {
  display: inline-block;
  background-color: rgba(0, 97, 170, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0061aa;
  padding: 0 9px;
  margin-right: 8px;
  margin-bottom: 8px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tags:hover {
  color: #fff;
  background-color: #e37021;
}

.post-block {
  padding: 0 16px;
}

.post-block:hover .post-block__image-hover {
  opacity: 1;
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.post-block:hover .post-block__image-hover img {
  -webkit-transform: translateX(50px);
  -moz-transform: translateX(50px);
  -ms-transform: translateX(50px);
  -o-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-transition: -webkit-transform 20s ease;
  -o-transition: -o-transform 20s ease;
  -moz-transition: transform 20s ease, -moz-transform 20s ease;
  transition: transform 20s ease;
  transition: transform 20s ease, -webkit-transform 20s ease,
    -moz-transform 20s ease, -o-transform 20s ease;
}

.post-block:hover .post-block__caption,
.post-block:hover .post-block__text {
  color: #fff;
}

.post-block__wrapper {
  border: 1px solid rgba(58, 69, 88, 0.2);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 40px;
}

.post-block__bg {
  background: -webkit-linear-gradient(
      314.14deg,
      #0061aa 12.34%,
      rgba(0, 97, 170, 0) 49.36%
    ),
    -webkit-linear-gradient(315.3deg, #008ed4 52.91%, rgba(0, 142, 212, 0) 84.1%),
    #0061aa;
  background: -moz-linear-gradient(
      314.14deg,
      #0061aa 12.34%,
      rgba(0, 97, 170, 0) 49.36%
    ),
    -moz-linear-gradient(315.3deg, #008ed4 52.91%, rgba(0, 142, 212, 0) 84.1%),
    #0061aa;
  background: -o-linear-gradient(
      314.14deg,
      #0061aa 12.34%,
      rgba(0, 97, 170, 0) 49.36%
    ),
    -o-linear-gradient(315.3deg, #008ed4 52.91%, rgba(0, 142, 212, 0) 84.1%),
    #0061aa;
  background: linear-gradient(
      135.86deg,
      #0061aa 12.34%,
      rgba(0, 97, 170, 0) 49.36%
    ),
    linear-gradient(134.7deg, #008ed4 52.91%, rgba(0, 142, 212, 0) 84.1%),
    #0061aa;
  z-index: 1;
}

.post-block__bg,
.post-block__bg-image,
.post-block__image-hover {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.post-block__bg-image {
  background-image: url(../img/line-bg.svg);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  z-index: 2;
}

.post-block__image-hover {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out, -moz-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out,
    -moz-transform 0.5s ease-in-out, -o-transform 0.5s ease-in-out;
  z-index: 3;
  opacity: 0;
  overflow: hidden;
}

.post-block__image-hover::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(1, 31, 84, 0.5)),
    to(rgba(1, 31, 84, 0.5))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: -o-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: linear-gradient(0deg, rgba(1, 31, 84, 0.5), rgba(1, 31, 84, 0.5));
  z-index: 2;
}

.post-block__image-hover img {
  width: 680px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  right: 0;
}

.post-block__wrap-img {
  position: relative;
  z-index: 4;
  max-width: 160px;
  width: 100%;
  height: 160px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid rgba(58, 69, 88, 0.2);
  margin-bottom: 38px;
}

.post-block__wrap-text {
  position: relative;
  z-index: 4;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
}

.post-block__up {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 3px;
  width: 100%;
  margin-bottom: 23px;
}

.post-block__caption {
  font-size: 24px;
  line-height: 32px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  position: relative;
  padding-bottom: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.block-btn--icon::before,
.post-block__caption::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #e37021;
}

.post-block__caption::before {
  width: 40px;
  height: 4px;
}

.post-block__down {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.post-block__text {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  margin-bottom: 32px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.block-btn,
.post-block__wrap-img {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-block__wrap-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block-btn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #e37021;
  padding: 12px 16px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  cursor: pointer;
}

.block-btn:hover .block-btn__wrap-svg {
  width: 48px;
  margin-right: -34px;
}

.block-btn__text {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  font-family: "Oswald", sans-serif;
  position: relative;
  z-index: 1;
}

.block-btn__arrow,
.block-btn__wrap-svg {
  height: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.block-btn__wrap-svg {
  overflow: hidden;
  margin-left: 16px;
  width: 14px;
  position: relative;
}

.block-btn__arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  min-width: 16px;
  stroke: #fff;
}

.block-btn__icon {
  width: 16px;
  min-width: 16px;
  margin-left: 14px;
}

.block-btn__svg {
  max-width: 16px;
  max-height: 16px;
  stroke: #fff;
  z-index: 1;
  margin-left: 15px;
}

.block-btn--icon,
.block-btn__svg {
  position: relative;
}

.block-btn--icon::before {
  top: 0;
  right: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.block-btn--icon:hover::before {
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  background-color: #0061aa;
}

.block-btn--back {
  background-color: #fff;
  border: 1px solid #d8dade;
}

.block-btn--back .block-btn__text {
  color: #3a4558;
}

.block-btn--back .block-btn__wrap-svg {
  margin-left: 0;
  margin-right: 16px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.block-btn--back .block-btn__arrow {
  stroke: #e37021;
}

.block-btn--back:hover .block-btn__wrap-svg {
  margin-right: 16px;
  margin-left: -32px;
}

.block-btn--change,
.block-btn--change .block-btn__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.block-btn--change {
  background-color: transparent;
  border: 2px solid #e37021;
}

.block-btn--change .block-btn__text {
  color: #e37021;
}

.block-btn--change .block-btn__arrow {
  stroke: #e37021;
}

.sm-icon {
  max-width: 27px;
  min-width: 27px;
  margin-right: 25px;
}

.sm-text,
.tel {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.sm-text {
  color: #fff;
  display: block;
  margin-right: 20px;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.1em;
}

.tel {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.tel,
.tel:hover {
  color: #fff;
}

.tel:focus {
  color: #fff;
}

.line-bottom {
  padding-bottom: 25px;
  position: relative;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
}

.line-bottom::before,
.topics__title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 40px;
  height: 4px;
  background-color: #e37021;
}

.doc-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 25px;
}

.doc-line:hover .doc-line__name {
  color: #0061aa;
}

.doc-line:last-of-type {
  margin-bottom: 0;
}

.doc-line__wrap-icon {
  width: 38px;
  min-width: 38px;
  margin-right: 25px;
  margin-top: 8px;
}

.doc-line__wrap-icon img {
  width: 100%;
}

.doc-line__name {
  display: block;
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.doc-line__file-info {
  font-size: 14px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
}

.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-bottom: 32px;
}

.pagination:last-child {
  margin-bottom: 0;
}

.pagination .block-btn {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #e37021;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

@media (max-width: 575px) {
  .pagination .block-btn {
    padding: 12px;
  }
}

.pagination .block-btn:first-child .block-btn__wrap-svg {
  margin-left: 0;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 16px;
}

.pagination .block-btn:first-child:hover .block-btn__wrap-svg {
  margin-left: -34px;
}

.pagination .block-btn__arrow,
.pagination .block-btn__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pagination .block-btn:hover,
.topic-line:hover .topic-line__delete::after,
.topic-line:hover .topic-line__delete::before {
  background-color: #fff;
}

.pagination .block-btn:hover .block-btn__arrow {
  stroke: #e37021;
}

.pagination .block-btn:hover .block-btn__text {
  color: #e37021;
}

@media (max-width: 767px) {
  .pagination .block-btn__text {
    display: none;
  }

  .pagination .block-btn__wrap-svg {
    margin: 0 !important;
  }
}

.pagination__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.pagination__item {
  padding: 0 8px;
}

@media (max-width: 1023px) {
  .pagination__item {
    padding: 0 4px;
  }
}

@media (max-width: 767px) {
  .pagination__item:nth-child(4),
  .pagination__item:nth-child(5),
  .pagination__item:nth-child(6) {
    display: none;
  }
}

.pagination__link {
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d8d6ca;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #3a4558;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-family: "Oswald", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pagination__link:hover {
  color: #fff;
  background-color: #e37021;
  border-color: #e37021;
}

.pagination__link:focus {
  color: #3a4558;
}

.pagination__link--active,
.pagination__link--active:hover {
  color: #fff;
  background-color: #0061aa;
  border-color: #0061aa;
}

.pagination__link--active:focus {
  color: #fff;
  background-color: #0061aa;
  border-color: #0061aa;
}

.no-border {
  border: 0;
}

.text-error {
  font-size: 16px;
  line-height: 24px;
  color: #df0f0f;
  display: block;
  margin-top: 12px;
}

.topics {
  margin-bottom: 37px;
}

.topics__title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  position: relative;
  margin-bottom: 29px;
}

.topics__text {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
  display: block;
  margin-bottom: 16px;
}

.topics__wrapper {
  font-size: 0;
  margin: 0 -4px 16px;
}

.topics__down .block-btn {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.topic-line {
  font-size: 0;
  padding: 0 4px;
  display: inline-block;
  margin-bottom: 8px;
  cursor: pointer;
}

.topic-line__wrapper {
  border: 1px solid #d8dade;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 11px 13.6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.topic-line__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.topic-line__delete {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  position: relative;
}

.topic-line__delete::after,
.topic-line__delete::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -webkit-calc(50% - 1px);
  left: -moz-calc(50% - 1px);
  left: calc(50% - 1px);
  width: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #e37021;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.topic-line__delete::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.topic-line:hover .topic-line__wrapper {
  background-color: #e37021;
  border-color: #e37021;
}

.topic-line:hover .topic-line__text {
  color: #fff;
}

.post-grid-line {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
}

.post-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  row-gap: 32px;
  margin-bottom: 40px;
}

@media (max-width: 1023px) {
  .post-grid-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .post-grid-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.my-modal .adrees-field .field__description {
  margin-right: 0;
}

.adrees-field {
  margin-bottom: 22px;
}

.adrees-field .field__description {
  margin-bottom: 8px;
  margin-right: 0 !important;
  max-width: inherit !important;
}

.adrees-field__wrap {
  position: relative;
}

.adrees-field__wrap--open input.adrees-field__field,
.drop-down-adrees {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  border: 1px solid #767d8b;
}

.adrees-field__wrap--open .drop-down-adrees {
  display: block;
}

.drop-down-adrees {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-top: none;
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  background-color: #fff;
  z-index: 10;
  overflow: hidden;
  display: none;
}

.drop-down-adrees__list {
  max-height: 193px;
  overflow: auto;
}

.drop-down-adrees__item {
  padding: 0 24px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.drop-down-adrees__item:first-child .drop-down-adrees__wrap {
  border: 0;
}

.drop-down-adrees__item:hover {
  background-color: rgba(216, 218, 222, 0.2);
}

.branch-post__text b,
.drop-down-adrees__item:hover .drop-down-adrees__text {
  color: #3a4558;
}

.drop-down-adrees__item:hover .drop-down-adrees__wrap,
.drop-down-adrees__item:hover
  + .drop-down-adrees__item
  .drop-down-adrees__wrap {
  border-color: transparent;
}

.drop-down-adrees__text,
.drop-down-adrees__wrap {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.drop-down-adrees__wrap {
  border-top: 1px solid #d8dade;
  padding: 15px 0;
}

.drop-down-adrees__text {
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
}

.accordion-row {
  border: 1px solid #d8d6ca;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.accordion-row:last-child,
.accordion-row__grid:last-child {
  margin-bottom: 0;
}

.accordion-row__up {
  position: relative;
  min-height: 56px;
  padding: 11px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-row__text,
.accordion-row__up::before {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.accordion-row__up::before {
  content: "";
  left: -1px;
  background-color: #e37021;
  right: -1px;
  bottom: -1px;
  top: -1px;
  opacity: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
}

.accordion-row__up:hover::before {
  opacity: 1;
  right: -8px;
  bottom: -8px;
  top: -8px;
  left: -8px;
}

.accordion-row__up:hover .accordion-row__text {
  color: #fff;
}

.accordion-row__up:hover .accordion-row__wrap-arrow {
  width: 40px;
  -webkit-transform: rotate(90deg) translateY(-12.5px);
  -moz-transform: rotate(90deg) translateY(-12.5px);
  -ms-transform: rotate(90deg) translateY(-12.5px);
  -o-transform: rotate(90deg) translateY(-12.5px);
  transform: rotate(90deg) translateY(-12.5px);
}

.accordion-row__up:hover .accordion-row__arrow {
  stroke: #fff;
}

.accordion-row__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .accordion-row__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}

.accordion-row__arrow,
.accordion-row__wrap-arrow {
  height: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.accordion-row__wrap-arrow {
  margin-left: 15px;
  min-width: 16px;
  overflow: hidden;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  z-index: 1;
  width: 16px;
  position: relative;
}

.accordion-row__arrow {
  position: absolute;
  top: 0;
  right: 0;
  stroke: #e37021;
  width: 40px;
}

.accordion-row__drop-down {
  -webkit-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
}

.accordion-row__wrapper {
  padding: 32px 40px;
}

@media (max-width: 1023px) {
  .accordion-row__wrapper {
    padding: 25px;
  }
}

@media (max-width: 767px) {
  .accordion-row__wrapper {
    padding: 16px;
  }
}

.accordion-row__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin-bottom: 22px;
}

@media (max-width: 1500px) {
  .accordion-row__grid {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 32px;
    -moz-column-gap: 32px;
    column-gap: 32px;
    row-gap: 22px;
  }
}

@media (max-width: 575px) {
  .accordion-row__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.accordion-row .text-area {
  margin-bottom: 28px;
}

.accordion-row .text-area:last-child {
  margin: 0;
}

.accordion-row.open-accordion > .accordion-row__up::before {
  opacity: 1;
  left: -1px;
  top: -1px;
  bottom: -1px;
  right: -1px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.accordion-row.open-accordion
  .accordion-row__up:hover
  .accordion-row__wrap-arrow {
  width: 16px;
  margin-left: 12.5px;
}

.accordion-row.open-accordion .accordion-row__up.active > .accordion-row__text {
  color: #fff;
}

.accordion-row.open-accordion .accordion-row__wrap-arrow {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.accordion-row.open-accordion .accordion-row__arrow,
.file-field__label:hover svg,
.link-row:hover .link-more__wrap-svg svg,
.link-row:hover .link-row__wrap-arrow svg,
.your-adrees__wrap-svg:hover svg {
  stroke: #fff;
}

.branch-post {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .branch-post {
    margin-bottom: 36px;
  }
}

.branch-post:last-child {
  margin-bottom: 0;
}

.branch-post__caption {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  padding-bottom: 8px;
  margin-bottom: 14px;
  position: relative;
}

.branch-post__caption::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 4px;
  background-color: #e37021;
}

@media (max-width: 767px) {
  .branch-post__caption {
    font-size: 18px;
    line-height: 24px;
  }
}

.branch-post__adrees {
  font-size: 18px;
  line-height: 32px;
  color: #3a4558;
  display: block;
}

@media (max-width: 767px) {
  .branch-post__adrees {
    font-size: 16px;
    line-height: 24px;
  }
}

.branch-post__tel {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: #3a4558;
  display: block;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .branch-post__tel {
    font-size: 18px;
    line-height: 40px;
  }
}

.branch-post__email {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.05em;
  color: #0061aa;
  display: inline-block;
  margin-bottom: 4px;
}

@media (max-width: 767px) {
  .branch-post__email {
    font-size: 18px;
    line-height: 40px;
  }
}

.branch-post__email:hover {
  text-decoration: underline;
  color: #0061aa;
}

.branch-post__text {
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
}

@media (max-width: 767px) {
  .branch-post__text {
    font-size: 14px;
  }
}

.select-error .custom-select__up {
  border-color: #df0f0f;
  -webkit-box-shadow: 0 0 0 1px #df0f0f;
  -moz-box-shadow: 0 0 0 1px #df0f0f;
  box-shadow: 0 0 0 1px #df0f0f;
}

.select-error .custom-select__up:hover {
  border-color: #df0f0f;
}

.custom-select,
.custom-select__up {
  width: 100%;
  position: relative;
}

.custom-select__up {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d8dade;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 11px 14px 11px 23px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-height: 48px;
}

.custom-select__up::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.custom-select__up:hover,
.custom-select__up:hover .custom-select__wrap-arrow {
  border-color: #767d8b;
}

.custom-select__up:hover .custom-select__arrow {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.custom-select__placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  margin-right: 32px;
}

.custom-select__wrap-arrow {
  border-left: 1px solid #d8dade;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-select__arrow {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
}

.custom-select__arrow path.line-arrow-animation {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.custom-select__drop-down {
  max-height: 246px;
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: #fff;
  z-index: 80;
  display: none;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #767d8b;
  border-top: none;
}

.custom-select__wrap-search {
  padding: 0 14px 0 23px;
  border-bottom: 1px solid #767d8b;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom-select__wrap-search input {
  width: 100%;
  font-size: 16px;
  line-height: normal;
  color: #3a4558;
  padding: 16px 0 16px 10px;
}

.custom-select__item,
.custom-select__item::before {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-select__item {
  padding: 0 24px;
  cursor: pointer;
  position: relative;
}

.custom-select__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(216, 218, 222, 0.2);
  opacity: 0;
  border-top: 1px solid #d8dade;
  border-bottom: 1px solid #d8dade;
}

.custom-select__item:hover::before {
  opacity: 1;
}

.custom-select__item:hover .custom-select__row-text,
.custom-select__item:hover + .custom-select__item .custom-select__row-text {
  border-color: transparent;
}

.custom-select__item--selected .custom-select__text,
.custom-select__item:hover .custom-select__text {
  color: #3a4558;
}

.custom-select__item:first-child .custom-select__row-text {
  border-color: transparent;
}

.custom-select__row-text,
.custom-select__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-select__row-text {
  border-top: 1px solid #d8dade;
  padding: 15px 0;
}

.custom-select__text {
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
  position: relative;
  z-index: 1;
}

.custom-select__backdrop {
  position: fixed;
  display: none;
  z-index: -1;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: transparent;
}

.custom-select.open .custom-select__up {
  border-color: #767d8b;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.custom-select.open .custom-select__arrow path.line-arrow-animation,
.custom-select.open .custom-select__placeholder {
  opacity: 1;
}

.custom-select.open .custom-select__wrap-arrow {
  border-color: #767d8b;
}

.custom-select.open .custom-select__arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.custom-select.open .custom-select__backdrop,
.custom-select.open .custom-select__drop-down {
  display: block;
}

.textarea--error .textarea__field {
  border-color: #df0f0f;
  -webkit-box-shadow: 0 0 0 1px #df0f0f;
  -moz-box-shadow: 0 0 0 1px #df0f0f;
  box-shadow: 0 0 0 1px #df0f0f;
}

.textarea__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.textarea__row .textarea__description {
  margin-bottom: 0;
  margin-right: 0;
}

.textarea__description {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  margin-right: 26px;
  margin-bottom: 8px;
  display: block;
}

.textarea__description span,
.textarea__field {
  font-size: 16px;
  line-height: 24px;
  color: #e37021;
}

.textarea__field {
  border: 1px solid #d8dade;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #3a4558;
  width: 100%;
  padding: 11px 23px;
  resize: none;
  min-height: 144px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.textarea__field:hover {
  border: 1px solid #767d8b;
}

.textarea__field:focus {
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  border: 1px solid #767d8b;
}

.file-field__label {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 13px;
  position: relative;
  cursor: pointer;
  margin-bottom: 33px;
}

.file-field svg,
.file-field__label::before,
.file-field__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.file-field__label::before {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #d8dade;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
}

.file-field__label:hover::before {
  left: -8px;
  right: -8px;
  top: -8px;
  bottom: -8px;
  background-color: #e37021;
  border-color: transparent;
}

.file-field__label:hover .file-field__text {
  color: #fff;
}

.file-field__input {
  display: none;
}

.file-field svg,
.file-field__text {
  position: relative;
  z-index: 1;
}

.file-field__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  margin-right: 16px;
}

.file-field svg {
  width: 16px;
  height: 14px;
  stroke: #e37021;
}

.file-field__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.file-field__row:last-child {
  margin-bottom: 0;
}

.file-field__icon {
  width: 22px;
  margin-right: 16px;
}

.file-field__name-field {
  font-size: 20px;
  color: #3a4558;
  line-height: 24px;
}

.file-field__delete {
  position: relative;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 5;
  margin-left: 5px;
}

.search-filter {
  border-top: 1px solid rgba(58, 69, 88, 0.2);
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding: 55px 0 40px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .search-filter {
    padding: 24px 0;
  }
}

.search-filter__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .search-filter__flex {
    display: block;
  }
}

.search-filter__wrap {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-right: 32px;
}

.search-filter__wrap .field {
  min-width: 352px;
}

@media (max-width: 767px) {
  .search-filter__wrap .field {
    min-width: inherit;
    margin-bottom: 16px;
  }

  .search-filter__wrap {
    margin-right: 0;
  }
}

@media (max-width: 1023px) {
  .search-filter__block {
    -webkit-flex-shrink: inherit;
    -ms-flex-negative: inherit;
    flex-shrink: inherit;
  }
}

.search-filter__sm-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

@media (max-width: 1023px) {
  .search-filter__sm-grid {
    grid-gap: 16px;
  }
}

@media (max-width: 767px) {
  .search-filter__sm-grid {
    display: block;
  }

  .search-filter__sm-grid .field {
    margin-bottom: 24px;
  }

  .search-filter__sm-grid .field__date {
    width: 100%;
  }
}

.search-filter__down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.post-work--border {
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding-bottom: 24px;
  margin-bottom: 28px;
}

.post-work .line-bottom {
  border-bottom: none;
  margin-bottom: 14px;
}

.post-work .post-work__date {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  display: block;
}

@media (max-width: 767px) {
  .post-work .post-work__date {
    font-size: 14px;
    line-height: 24px;
  }
}

.post-work .post-work__caption {
  margin-bottom: 17px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
}

@media (max-width: 767px) {
  .post-work .post-work__caption {
    font-size: 18px;
    line-height: 24px;
  }
}

.post-work__caption {
  font-family: "Oswald", sans-serif;
  padding-bottom: 12px;
  position: relative;
}

.post-work__caption::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 40px;
  background-color: #e37021;
}

.post-work .post-work__text {
  font-size: 18px;
  line-height: normal;
  color: #767d8b;
  margin-bottom: 0;
  font-weight: 400;
  opacity: 1;
}

@media (max-width: 767px) {
  .post-work .post-work__text {
    font-size: 16px;
    line-height: 24px;
  }
}

.border-top-block {
  border-top: 1px solid rgba(58, 69, 88, 0.2);
  padding-top: 28px;
}

@media (max-width: 1023px) {
  .border-top-block {
    padding-top: 0;
    border: 0;
  }
}

.grid-list {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 767px) {
  .grid-list {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }
}

.grid-list li {
  max-width: 542px;
}

.disabled-select {
  position: relative;
}

.disabled-select .custom-select__up {
  background-color: #f1f3f6;
}

.disabled-select::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.text-area ul li a {
  font-size: 16px;
  line-height: 24px;
}

.text-area ul li p,
.text-area ul li span,
.text-sm-info {
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
}

.text-sm-info em {
  color: #3a4558;
  font-style: normal;
}

.circle-hint {
  cursor: pointer;
  position: relative;
}

.circle-hint svg circle,
.circle-hint__hint {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.circle-hint svg circle {
  fill: #e37021;
}

.circle-hint:hover svg circle,
.field__circle-hint:hover svg {
  fill: #0061aa;
}

.circle-hint:hover .circle-hint__hint {
  opacity: 1;
  pointer-events: unset;
}

.circle-hint__hint {
  position: absolute;
  left: 11px;
  top: 100%;
  padding-top: 8px;
  z-index: 20;
  min-width: 360px;
  max-width: 400px;
  opacity: 0;
  pointer-events: none;
}

.circle-hint__wrap-text {
  background-color: #fff;
  position: relative;
  -webkit-border-radius: 0 4px 4px 4px;
  -moz-border-radius: 0 4px 4px 4px;
  border-radius: 0 4px 4px 4px;
  padding: 12px;
}

.circle-hint__wrap-text::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 8px;
  height: 8px;
  /* background-image: url(../img/tringle-white.svg); */
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
}

.circle-hint__wrap-text p,
.circle-hint__wrap-text span,
.company-details__wrap ul li span {
  font-size: 16px;
  line-height: normal;
  color: #3a4558;
}

.marquee-line {
  margin-bottom: 61px;
}

.marquee-line__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 28px 0 36px;
  margin-left: 20px;
}

.marquee-line__icon {
  max-width: 24px;
  min-width: 24px;
  margin-right: 16px;
}

.marquee-line__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  margin-right: 16px;
}

.news-sect {
  margin-bottom: 64px;
}

.news-sect:first-child {
  margin-top: 61px;
}

.news-sect__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.grid-posts {
  margin-bottom: 32px;
}

.grid-posts__wrapper {
  margin: 0 -16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.grid-posts__wrapper .post-block {
  width: 25%;
  margin-bottom: 32px;
}

.grid-posts__wrapper .post-block:first-child .post-block__wrap-img,
.grid-posts__wrapper .post-block:first-child .post-block__wrapper,
.grid-posts__wrapper .post-block:nth-child(2) .post-block__wrap-img,
.grid-posts__wrapper .post-block:nth-child(2) .post-block__wrapper,
.grid-posts__wrapper .post-block:nth-child(3) .post-block__wrap-img,
.grid-posts__wrapper .post-block:nth-child(3) .post-block__wrapper,
.grid-posts__wrapper .post-block:nth-child(4) .post-block__wrap-img,
.grid-posts__wrapper .post-block:nth-child(4) .post-block__wrapper {
  border: 0;
}

.grid-posts__wrapper .post-block:first-child .post-block__caption,
.grid-posts__wrapper .post-block:first-child .post-block__text,
.grid-posts__wrapper .post-block:nth-child(2) .post-block__caption,
.grid-posts__wrapper .post-block:nth-child(2) .post-block__text,
.grid-posts__wrapper .post-block:nth-child(3) .post-block__caption,
.grid-posts__wrapper .post-block:nth-child(3) .post-block__text,
.grid-posts__wrapper .post-block:nth-child(4) .post-block__caption,
.grid-posts__wrapper .post-block:nth-child(4) .post-block__text {
  color: #fff;
}

.line-contacts {
  background-color: #0061aa;
  padding: 27px 0;
}

.line-contacts__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.line-contacts__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.line-contacts__wrap .sm-text {
  max-width: 368px;
}

.header-inner {
  position: relative;
}

@media (max-width: 1200px) {
  .header-inner--mb {
    margin-bottom: 54px;
  }
}

@media (max-width: 767px) {
  .header-inner--mb {
    margin-bottom: 0;
  }
}

.first-screen-inner {
  position: relative;
}

.first-screen-inner::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(1, 31, 84, 0.5)),
    to(rgba(1, 31, 84, 0.5))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: -o-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: linear-gradient(0deg, rgba(1, 31, 84, 0.5), rgba(1, 31, 84, 0.5));
  z-index: 2;
}

.first-screen-inner__bg-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.first-screen-inner__bg-image img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  height: 100%;
  width: 100%;
}

.first-screen-inner__container {
  min-height: 648px;
  padding: 170px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

@media (max-width: 1500px) {
  .first-screen-inner__container {
    min-height: 536px;
  }
}

@media (max-width: 1100px) {
  .first-screen-inner__container {
    min-height: 480px;
  }
}

@media (max-width: 1023px) {
  .first-screen-inner__container {
    min-height: 400px;
  }
}

.first-screen-inner .tags {
  color: #fff;
}

.first-screen-inner .tags:last-child {
  background-color: rgba(255, 255, 255, 0.2);
}

.first-screen-inner__down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.first-screen-inner__down .block-btn {
  margin-left: 32px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.title-page {
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  font-family: "Oswald", sans-serif;
}

.page-grid {
  position: relative;
  z-index: 5;
}

.page-grid__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -16px;
}

.page-grid__sidebar {
  max-width: 456px;
  min-width: 456px;
  padding: 0 16px;
}

@media (max-width: 1500px) {
  .page-grid__sidebar {
    max-width: 336px;
    min-width: 336px;
  }

  .page-grid__sidebar .link-block {
    padding: 0 24px;
  }

  .page-grid__sidebar .link-block__wrap-hover {
    margin-left: 9px;
  }
}

@media (max-width: 1200px) {
  .page-grid__sidebar {
    min-width: inherit;
  }

  .page-grid__sidebar .link-block {
    padding: 0 0 12px;
  }
}

@media (max-width: 767px) {
  .page-grid__sidebar .link-block {
    padding: 0 0 8px;
    margin-bottom: 16px;
  }
}

.page-grid__content {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 64px 16px;
}

.link-block {
  background-color: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  margin-top: -72px;
  padding: 0 41px;
  margin-bottom: 37px;
}

.link-block__up {
  display: none;
}

.link-block__item:first-child .link-block__link-row {
  border-top: none;
}

.link-block__item--active {
  position: relative;
  border-color: transparent;
}

.link-block__item--active::before {
  content: "";
  position: absolute;
  left: -10px;
  top: -1px;
  bottom: -1px;
  right: -10px;
  background-color: #0061aa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-block__item--active .link-block__link-row:hover .link-block__text,
.link-block__item--active .link-block__text {
  color: #fff;
  -webkit-transform: translateX(16px);
  -moz-transform: translateX(16px);
  -ms-transform: translateX(16px);
  -o-transform: translateX(16px);
  transform: translateX(16px);
}

.link-block__item--active .link-block__wrap-hover {
  width: 0;
  overflow: hidden;
  margin: 0;
}

.link-block__item--active .link-block__link-row .link-block__wrap-hover {
  width: 0;
  overflow: hidden;
  margin: 0;
  z-index: -1;
}

.link-block__item--mob {
  display: none;
}

@media (max-width: 1200px) {
  .link-block__item--mob {
    display: block;
  }
}

.link-block__link-row,
.link-block__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-block__link-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid transparent;
  border-top: 1px dashed rgba(58, 69, 88, 0.2);
  padding: 15.5px 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.link-block__link-row:hover {
  border: 1px solid #d8dade;
}

.link-block__link-row:hover .link-block__hover-arrow {
  width: 40px;
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  -ms-transform: translateX(20px);
  -o-transform: translateX(20px);
  transform: translateX(20px);
}

.link-block__link-row:hover .link-block__text {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}

.link-block__text {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: normal;
  position: relative;
  z-index: 1;
}

.link-block__wrap-hover {
  min-width: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: 15px;
}

@media (max-width: 1360px) {
  .link-block__wrap-hover {
    margin-left: 9px;
  }
}

.link-block__hover-arrow {
  width: 16px;
  height: 10px;
  overflow: hidden;
  margin-top: 2px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-back__wrap-arrow svg,
.link-block__hover-arrow svg {
  stroke: #e37021;
  width: 40px;
  height: 10px;
}

.link-block__item:hover + .link-block__item .link-block__link-row {
  border-top-color: transparent;
}

.link-back {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
}

.link-back__wrap-arrow {
  width: 16px;
  min-width: 16px;
  overflow: hidden;
  height: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-right: 17px;
}

.link-back__wrap-arrow svg {
  min-width: 40px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.link-back:hover .link-back__wrap-arrow {
  width: 40px;
  min-width: 40px;
  margin-left: -24px;
}

.link-back__text {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}

.block-info {
  margin-bottom: 64px;
}

.block-info:last-child,
.block-info__down .text-block:last-child {
  margin-bottom: 0;
}

.block-info__up {
  position: relative;
}

.block-info__up:hover .block-info__wrap-img img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.block-info__wrap-img,
.block-info__wrap-img picture::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.block-info__wrap-img img,
.block-info__wrap-img picture {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.block-info__wrap-img picture {
  position: relative;
  overflow: hidden;
}

.block-info__wrap-img picture::before {
  content: "";
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(1, 31, 84, 0.4)),
    to(rgba(1, 31, 84, 0.4))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.4),
    rgba(1, 31, 84, 0.4)
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.4),
    rgba(1, 31, 84, 0.4)
  );
  background: -o-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.4),
    rgba(1, 31, 84, 0.4)
  );
  background: linear-gradient(0deg, rgba(1, 31, 84, 0.4), rgba(1, 31, 84, 0.4));
  z-index: 2;
}

.block-info__wrap-img img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out, -moz-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out,
    -moz-transform 0.5s ease-in-out, -o-transform 0.5s ease-in-out;
}

.block-info__wrap-text {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 480px;
  padding: 40px;
}

.block-info__caption {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 24px;
  margin-bottom: 8px;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 840px;
}

.block-info__caption::before,
.link-row::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #e37021;
}

.block-info__caption::before {
  width: 40px;
  height: 4px;
  bottom: 4px;
}

.block-info__grid {
  padding-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  row-gap: 16px;
}

.block-info__down {
  padding-top: 32px;
}

.block-info__down .text-block {
  margin-bottom: 17px;
}

.link-row {
  min-height: 56px;
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  position: relative;
}

.link-row::before {
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.link-row:hover::before {
  left: -8px;
  top: -8px;
  bottom: -8px;
  right: -8px;
  opacity: 1;
}

.field__date .datetimepicker .header-picker div span,
.field__date .datetimepicker .header-picker span,
.field__date .datetimepicker .header-picker span div,
.link-row:hover .link-more__text,
.link-row:hover .link-row__text {
  color: #fff;
}

.link-row:hover .link-more__wrap-svg,
.link-row:hover .link-row__wrap-arrow {
  width: 40px;
  margin-right: 0;
}

.link-row__text {
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.link-row__wrap-arrow {
  margin-left: 0;
  width: 16px;
  position: relative;
  z-index: 1;
}

.link-row__arrow {
  width: 40px;
}

.link-row--change {
  margin-bottom: 16px;
}

.link-row--change:last-child {
  margin-bottom: 0;
}

.tel-contacts,
.text-block h4 {
  text-transform: uppercase;
  color: #3a4558;
}

.text-block h4 {
  opacity: 0.8;
  display: block;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 16px;
  font-family: Oswald;
  letter-spacing: 0.1em;
  font-weight: 400;
}

.text-block p,
.text-block span {
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: #3a4558;
}

.text-block a {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: #0061aa;
}

.tel-contacts {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
}

.search-value-block__text-info b,
.tel-contacts:hover,
.vacancies-block__tel:hover span {
  color: #3a4558;
}

.tel-contacts:focus {
  color: #3a4558;
}

.wrapper-link {
  margin-bottom: 62px;
}

.row-docs-age:last-child,
.text-area:last-child,
.wrapper-link:last-child {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .wrapper-link {
    margin-bottom: 37px;
  }
}

.row-docs-age {
  margin-bottom: 37px;
}

.row-docs-age__caption {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  margin-bottom: 24px;
}

.link-hh {
  padding: 12px 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

.link-hh:hover::before {
  left: -8px;
  top: -8px;
  bottom: -8px;
  right: -8px;
}

.link-hh::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #de2326;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-hh__img {
  width: 48px;
  min-width: 48px;
  margin-right: 24px;
  position: relative;
  z-index: 1;
}

.link-hh__text {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  z-index: 1;
}

.text-area,
.text-area p,
.text-area ul {
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .text-area {
    margin-bottom: 24px;
  }
}

.text-area p.text-md {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 22px;
  opacity: 1;
  color: #3a4558;
}

.text-area p {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
}

.text-area p em {
  font-style: normal;
  color: #3a4558;
}

.text-area ol li:last-child,
.text-area ol:last-child,
.text-area p:last-child,
.text-area ul li:last-child,
.text-area ul:last-child {
  margin-bottom: 0;
}

.form-page__text a,
.text-area a {
  font-size: 16px;
  line-height: 24px;
}

.text-area a.email-link:hover,
.text-area a:hover,
.text-area ul li a:hover {
  text-decoration: underline;
}

.text-area a.email-link,
.text-area h3,
.text-area span strong {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  letter-spacing: 0.05em;
}

.text-area h3,
.text-area h4 {
  line-height: 32px;
  color: #3a4558;
}

.text-area h3 {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.text-area h4 {
  font-weight: 400;
  font-size: 20px;
  display: block;
  margin-bottom: 23px;
}

.text-area ol li,
.text-area ul li {
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
  margin-bottom: 24px;
  position: relative;
  padding-left: 32px;
}

.form-page__text a,
.text-area a,
.text-area ul li a {
  color: #0061aa;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.text-area ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  background-image: url(../img/check-icon.svg);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.text-area ol {
  margin-bottom: 32px;
  counter-reset: item;
}

.text-area ol li::before {
  content: counter(item) ". ";
  counter-increment: item;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 24px;
  color: #e37021;
  font-weight: 700;
}

.text-area span {
  display: block;
}

.text-area a.email-link,
.text-area span strong {
  line-height: 40px;
  margin-bottom: 5px;
}

.text-area span strong {
  font-weight: 700;
  text-transform: uppercase;
  color: #3a4558;
}

@media (max-width: 575px) {
  .text-area span strong {
    font-size: 20px;
    line-height: 28px;
  }
}

.text-area a.email-link {
  color: #0061aa;
  display: inline-block;
}

.text-area table {
  border-collapse: collapse;
  width: 100%;
  border-top: 2px solid #e37021;
}

.text-area table tr:nth-child(2n) td,
.text-area table tr:nth-child(2n) th {
  background-color: rgba(58, 69, 88, 0.06);
}

.text-area table th {
  text-align: start;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  padding: 14px 0;
}

.text-area table td:first-child,
.text-area table th:first-child {
  padding-left: 24px;
}

.text-area table td:last-child,
.text-area table th:last-child {
  padding-right: 24px;
}

.text-area table td {
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
  padding: 14px 0;
  /* text-align: center; */
  vertical-align: middle;
}

.border-bottom {
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding-bottom: 24px;
}

.grid-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin-bottom: 52px;
}

.grid-block--v2 {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1660px) {
  .grid-block {
    grid-template-columns: repeat(2, 1fr);
  }
}

.vacancies-block {
  border: 1px solid rgba(58, 69, 88, 0.2);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 40px;
  display: block;
}

.reviews-post:hover .reviews-post__name,
.vacancies-block:hover .vacancies-block__caption {
  color: #0061aa;
}

.vacancies-block__caption {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  margin-bottom: 17px;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.vacancies-block__email,
.vacancies-block__tel,
.vacancies-block__text {
  font-size: 16px;
  line-height: 32px;
  color: #767d8b;
  display: block;
}

.vacancies-block__email span,
.vacancies-block__tel span {
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: #3a4558;
}

.vacancies-block__email span::after,
.vacancies-block__tel span::after {
  content: " ";
}

.vacancies-block__email:hover,
.vacancies-block__tel:hover {
  color: #767d8b;
}

.vacancies-block__email span {
  font-weight: 400;
  color: #0061aa;
}

.vacancies-block__email:hover span {
  color: #0061aa;
  text-decoration: underline;
}

.vacancies-block__text {
  margin-bottom: 17px;
}

.image-content:last-child,
.vacancies-block__text:last-child {
  margin-bottom: 0;
}

.image-content {
  max-width: 1032px;
  width: 100%;
  margin: 0 auto 40px;
}

.image-content img {
  width: 100%;
}

.reviews-post {
  margin-bottom: 32px;
}

.reviews-post:hover .reviews-post__wrap-img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.reviews-post--grid {
  display: grid;
  grid-template-columns: 272px 1fr;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 32px;
}

@media (max-width: 650px) {
  .reviews-post--grid {
    display: block;
  }
}

.reviews-post--grid .reviews-post__wrapper {
  display: block;
}

.reviews-post__wrap-img {
  width: 100%;
}

@media (max-width: 650px) {
  .reviews-post__wrap-img {
    max-width: 240px;
  }
}

.reviews-post__wrap-img img {
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
}

.reviews-post__wrapper {
  display: grid;
  grid-template-columns: 272px 1fr;
  grid-gap: 32px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 1023px) {
  .reviews-post__wrapper {
    display: block;
  }
}

.reviews-post__wrap-info {
  padding-bottom: 16px;
  position: relative;
  margin-bottom: 18px;
}

.reviews-post__wrap-info::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 24px;
  background-color: #e37021;
}

.reviews-post__date {
  font-size: 12px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
  margin-bottom: 9px;
  display: block;
}

.reviews-post__name {
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  display: block;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.reviews-post__text-info,
.reviews-post__wrap-text p {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
  display: block;
}

.your-adrees__text-info {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  display: block;
  margin-bottom: 8px;
}

.your-adrees__field {
  padding: 11px 23px;
  margin-bottom: 23px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.your-adrees__adrees-field--change,
.your-adrees__field {
  border: 1px solid #d8dade;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  width: 100%;
}

.adrees-field__field,
.field__input,
.your-adrees__adrees-field--change,
.your-profile__field {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.adrees-field__field,
.field__input,
.my-modal .field__field,
.my-modal .topics-modal__field,
.your-profile__field {
  border: 1px solid #d8dade;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  width: 100%;
  padding: 11px 23px;
}

.adrees-field__field:hover,
.field__input:hover,
.my-modal .field__field:hover,
.my-modal .topics-modal__field:hover,
.your-adrees__adrees-field--change:hover,
.your-adrees__field:hover,
.your-profile__field:hover {
  border: 1px solid #767d8b;
}

.adrees-field__field:focus,
.field__input:focus,
.my-modal .field__field:focus,
.my-modal .topics-modal__field:focus,
.your-adrees__adrees-field--change:focus,
.your-adrees__field:focus,
.your-profile__field:focus {
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  border: 1px solid #767d8b;
}

.your-adrees .block-btn {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.your-adrees .block-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #e37021;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.your-adrees .block-btn:hover::before,
.your-profile .block-btn:hover::before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

.your-adrees .block-btn__svg {
  stroke: #fff;
  width: 16px;
  height: 16px;
  margin-left: 14px;
  position: relative;
  z-index: 1;
}

.your-adrees .block-btn__text {
  position: relative;
  z-index: 1;
}

.your-adrees__wrapper {
  margin-bottom: 20px;
}

.your-adrees__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 8px;
}

@media (max-width: 767px) {
  .your-adrees__row {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.your-adrees__row:nth-child(2n + 1) {
  background-color: rgba(58, 69, 88, 0.06);
}

.your-adrees__row--active,
.your-adrees__row:hover {
  background-color: #e7e8eb;
}

.your-adrees__left {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.your-adrees__adrees-field {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  width: 100%;
  padding: 8px 110px 8px 22px;
  background-color: transparent;
}

.your-adrees__adrees-field--change {
  margin-bottom: 0;
  padding: 7px 62px 7px 22px;
  background-color: #fff;
}

@media (max-width: 767px) {
  .your-adrees__adrees-field {
    padding-right: 22px;
  }
}

.your-adrees__sm-flex {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .your-adrees__sm-flex {
    right: auto;
    left: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 8px;
    bottom: auto;
  }
}

.your-adrees__wrap-svg {
  width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-right: 8px;
}

.your-adrees__wrap-svg:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .your-adrees__wrap-svg {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.your-adrees__wrap-svg:hover {
  background-color: #e37021;
}

.your-adrees__wrap-svg--change .your-adrees__svg--one {
  display: none;
}

.your-adrees__wrap-svg--change .your-adrees__svg--two {
  display: block;
}

.your-adrees__svg {
  stroke: #0061aa;
  width: 16px;
  height: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.your-adrees__svg--two {
  display: none;
  stroke: #e37021;
}

.your-adrees__right {
  margin-left: 8px;
}

@media (max-width: 767px) {
  .your-adrees__right {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-top: 96px;
  }
}

.your-adrees__close,
.your-adrees__close::after,
.your-adrees__close::before {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.your-adrees__close {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.your-adrees__close:hover {
  background-color: #e37021;
}

.your-adrees__close:hover::after,
.your-adrees__close:hover::before {
  background-color: #fff;
}

.your-adrees__close::after,
.your-adrees__close::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  left: -webkit-calc(50% - 1px);
  left: -moz-calc(50% - 1px);
  left: calc(50% - 1px);
  top: -webkit-calc(50% - 10px);
  top: -moz-calc(50% - 10px);
  top: calc(50% - 10px);
  background-color: #0061aa;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.your-adrees__close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.tab-2 .text-line {
  margin-bottom: 32px;
}

.tab-2 .text-line h3 {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .your-profile__form .text-line {
    margin-bottom: 16px;
  }
}

.your-profile .text-line {
  margin-bottom: 15px;
  padding-bottom: 18px;
}

.your-profile__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  margin-bottom: 40px;
}

.your-profile__grid:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .your-profile__grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
    margin-bottom: 16px;
  }
}

.your-profile__description {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  display: block;
  margin-bottom: 16px;
}

.adrees-field__field,
.field__input,
.your-profile__field {
  margin-bottom: 0;
}

.your-profile .block-btn {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.your-profile .block-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #e37021;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.your-profile .block-btn__svg {
  stroke: #fff;
  width: 16px;
  height: 16px;
  margin-left: 14px;
  position: relative;
  z-index: 1;
}

.your-profile .block-btn__text {
  position: relative;
  z-index: 1;
}

.your-profile__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.your-profile__caption {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
  min-width: 120px;
  margin-right: 32px;
}

.your-profile__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.your-profile__text {
  font-size: 20px;
  line-height: normal;
  margin-right: 24px;
}

.square-wrap-svg {
  width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #0061aa;
}

.breadcrumbs .tags:hover,
.square-wrap-svg:hover {
  background-color: #e37021;
}

.square-wrap-svg svg {
  stroke: #fff;
  width: 16px;
  height: 16px;
}

.breadcrumbs {
  margin-bottom: 4px;
}

.breadcrumbs .tags {
  background-color: #0061aa;
  color: #fff;
}

.breadcrumbs .tags:last-child,
.breadcrumbs .tags:last-child:hover {
  background-color: rgba(58, 69, 88, 0.45);
}

.inner-post {
  margin-bottom: 64px;
  padding-top: 31px;
}

@media (max-width: 1359px) {
  .inner-post {
    padding-top: 32px;
  }
}

@media (max-width: 767px) {
  .inner-post {
    margin-bottom: 40px;
  }
}

.inner-post__up,
.post-text-area p {
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .inner-post__up {
    margin-bottom: 32px;
  }
}

.inner-post__title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  display: block;
  margin-bottom: 20px;
}

@media (max-width: 1500px) {
  .inner-post__title {
    font-size: 56px;
    line-height: 64px;
  }
}

@media (max-width: 1359px) {
  .inner-post__title {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .inner-post__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }
}

.inner-post__date {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
}

.inner-post__middle {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .inner-post__middle {
    margin-bottom: 32px;
  }
}

.inner-post__down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.inner-post__down .block-btn {
  border: 1px solid #e37021;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inner-post__down .block-btn__wrap-svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 16px;
  margin-left: 0;
}

.inner-post__down .block-btn__svg,
.inner-post__down .block-btn__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-page__down .block-btn:hover,
.inner-post__down .block-btn:hover {
  background-color: transparent;
}

.inner-post__down .block-btn:hover .block-btn__wrap-svg {
  margin-right: 16px;
  width: 48px;
  margin-left: -34px;
}

.inner-post__down .block-btn:hover .block-btn__arrow {
  stroke: #e37021;
}

.form-page__down .block-btn:hover .block-btn__text,
.inner-post__down .block-btn:hover .block-btn__text {
  color: #e37021;
}

@media (max-width: 767px) {
  .inner-post__down {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-box-orient: vertical;
    -moz-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .inner-post__down .download-btn {
    margin-bottom: 16px;
  }
}

.center-wrapper {
  max-width: 66.16%;
  margin: 0 auto;
}

@media (max-width: 1500px) {
  .center-wrapper {
    max-width: 83.304%;
  }
}

@media (max-width: 1359px) {
  .center-wrapper {
    max-width: 77.882%;
  }
}

@media (max-width: 1023px) {
  .center-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .center-wrapper {
    max-width: 100%;
  }
}

.slider-gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (max-width: 1200px) {
  .slider-gallery {
    display: block;
  }
}

@media (max-width: 1023px) {
  .slider-gallery {
    max-width: 608px;
    margin: 0 auto;
  }
}

.slider-gallery__up {
  width: 87%;
}

@media (max-width: 2000px) {
  .slider-gallery__up {
    max-height: 760px;
  }
}

@media (max-width: 1500px) {
  .slider-gallery__up {
    width: 86.8%;
    max-height: 696px;
  }
}

@media (max-width: 1200px) {
  .slider-gallery__up {
    width: auto;
    position: relative;
  }
}

.slider-gallery__lg {
  height: 100%;
  width: 100%;
}

.post-text-area .slider-gallery img,
.slider-gallery__lg-wrapper .slider-gallery__lg-slide picture {
  max-height: inherit;
}

@media (max-width: 1200px) {
  .slider-gallery__lg-slide {
    padding-bottom: 0;
  }
}

.slider-gallery__lg-slide picture,
.slider-gallery__sm-slide picture {
  margin-bottom: 0 !important;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.slider-gallery__lg-slide picture {
  height: 100%;
}

.slider-gallery__lg-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media (max-width: 1200px) {
  .slider-gallery__lg-slide img {
    height: auto;
  }
}

.slider-gallery__sm {
  min-width: 13%;
  padding-left: 32px;
}

@media (max-width: 2000px) {
  .slider-gallery__sm {
    max-height: 760px;
  }
}

@media (max-width: 1500px) {
  .slider-gallery__sm {
    min-width: 144px;
    padding-left: 40px;
    max-height: 696px;
  }
}

@media (max-width: 1200px) {
  .slider-gallery__sm {
    min-width: inherit;
    max-width: inherit;
    padding: 24px 8px 0;
    width: auto;
  }
}

@media (max-width: 1023px) {
  .slider-gallery__sm {
    padding: 24px 0 0;
  }
}

.slider-gallery__sm .swiper-slide-thumb-active picture::before,
.slider-gallery__sm .swiper-slide-thumb-active::before {
  opacity: 1;
}

.slider-gallery__sm-slide {
  cursor: pointer;
  position: relative;
}

.slider-gallery__sm-slide picture {
  position: relative;
}

.slider-gallery__arrow,
.slider-gallery__sm-slide::before {
  position: absolute;
  top: -webkit-calc(50% - 5px);
  top: -moz-calc(50% - 5px);
  top: calc(50% - 5px);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.slider-gallery__sm-slide::before {
  content: "";
  right: 100%;
  height: 10px;
  /* background-image: url(../img/sm-arrow.svg); */
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  margin-right: 10px;
  width: 10px;
}

@media (max-width: 1500px) {
  .slider-gallery__sm-slide::before {
    margin-right: 15px;
  }
}

.slider-gallery__sm-slide picture::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(1, 31, 84, 0.5)),
    to(rgba(1, 31, 84, 0.5))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: -o-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.5),
    rgba(1, 31, 84, 0.5)
  );
  background: linear-gradient(0deg, rgba(1, 31, 84, 0.5), rgba(1, 31, 84, 0.5));
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.slider-gallery__sm-slide img {
  width: 100%;
}

@media (max-width: 1200px) {
  .slider-gallery__sm-slide::before {
    top: auto !important;
    right: auto;
    left: -webkit-calc(50% - 4px);
    left: -moz-calc(50% - 4px);
    left: calc(50% - 4px);
    margin-right: 0;
    bottom: 100%;
    margin-bottom: 6px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.slider-gallery__arrow {
  z-index: 1;
  width: 48px;
  overflow: hidden;
}

.slider-gallery__arrow:hover {
  width: 62px;
}

.slider-gallery__arrow svg {
  stroke: #e37021;
  width: 62px;
  height: 10px;
  cursor: pointer;
}

.slider-gallery__arrow--next {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  left: 100%;
  margin-left: 9%;
}

@media (max-width: 1500px) {
  .slider-gallery__arrow--next {
    margin-left: 66px;
  }
}

@media (max-width: 1200px) {
  .slider-gallery__arrow--next {
    margin-left: 33px;
  }
}

@media (max-width: 1023px) {
  .slider-gallery__arrow--next {
    margin-left: 17px;
  }
}

@media (max-width: 767px) {
  .slider-gallery__arrow--next {
    left: auto;
    right: 17px;
    margin: 0;
  }
}

.slider-gallery__arrow--prev {
  right: 100%;
  margin-right: 9%;
}

@media (max-width: 1500px) {
  .slider-gallery__arrow--prev {
    margin-right: 66px;
  }
}

@media (max-width: 1200px) {
  .slider-gallery__arrow--prev {
    margin-right: 33px;
  }
}

@media (max-width: 1023px) {
  .slider-gallery__arrow--prev {
    margin-right: 17px;
  }
}

@media (max-width: 767px) {
  .slider-gallery__arrow--prev {
    right: auto;
    left: 17px;
    margin: 0;
  }
}

.post-text-area .slider-gallery {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .post-text-area .slider-gallery {
    margin-bottom: 20px;
  }
}

.post-text-area img,
.post-text-area picture {
  max-width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.post-text-area picture {
  overflow: hidden;
  margin: 0 auto 30px;
}

.post-text-area img {
  max-height: 760px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1500px) {
  .post-text-area img {
    max-height: 696px;
  }
}

@media (max-width: 1359px) {
  .post-text-area img {
    max-height: 488px;
  }
}

@media (max-width: 1023px) {
  .post-text-area img {
    max-height: 464px;
  }
}

@media (max-width: 575px) {
  .post-text-area img {
    max-height: 232px;
  }
}

.post-text-area p {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
  display: block;
}

.download-btn {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 8px 16px;
}

.download-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #0061aa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.download-btn__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  z-index: 1;
}

.download-btn__svg {
  width: 24px;
  height: 24px;
  stroke: #fff;
  margin-left: 13px;
  position: relative;
}

.download-btn:hover::before {
  left: -4px;
  top: -4px;
  bottom: -4px;
  right: -4px;
}

.contacts-row,
.contacts-row__wrap-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.contacts-row {
  margin-bottom: 16px;
}

.contacts-row__wrap-icon {
  min-width: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contacts-row__caption {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
  display: block;
  margin-bottom: 3px;
}

.contacts-row__text {
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  display: block;
}

@media (max-width: 575px) {
  .contacts-row__text {
    font-size: 18px;
    line-height: 26px;
  }
}

.contacts-row__mail {
  font-size: 20px;
  line-height: 32px;
  color: #0061aa;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.contacts-row__mail:hover,
.form-page__text a:hover {
  color: #e37021;
  text-decoration: underline;
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
}

.wrap-nav-scroll {
  overflow: auto;
  margin: 0 -12px 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1200px) {
  .wrap-nav-scroll {
    margin: 0 -32px 40px;
  }
}

.nav-year {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0 0 4px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 1200px) {
  .nav-year {
    padding: 0 20px 4px;
  }
}

.nav-year::before {
  content: "";
  position: absolute;
  left: 12px;
  bottom: 0;
  right: 12px;
  height: 1px;
  background-color: rgba(58, 69, 88, 0.2);
}

@media (max-width: 1200px) {
  .nav-year::before {
    right: 32px;
    left: 32px;
  }
}

.nav-year__item {
  padding: 0 12px;
}

.nav-year__item--active .nav-year__text::before,
.nav-year__text:hover::before {
  right: 0;
}

.nav-year__text {
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  display: block;
  padding-bottom: 15px;
  position: relative;
  cursor: pointer;
}

.nav-year__text::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  right: 100%;
  background-color: #e37021;
}

.newspaper-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
}

@media (max-width: 1023px) {
  .newspaper-grid {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 32px;
    -moz-column-gap: 32px;
    column-gap: 32px;
    row-gap: 24px;
  }
}

@media (max-width: 575px) {
  .newspaper-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.newspaper-post__wrap-img {
  margin-bottom: 32px;
}

.nav-year__text::before,
.newspaper-post__up .doc-line::before,
.newspaper-post__wrap-img picture {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.newspaper-post__wrap-img picture {
  max-width: 272px;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 575px) {
  .newspaper-post__wrap-img picture {
    max-width: 152px;
  }
}

.newspaper-post__wrap-img picture:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.newspaper-post__wrap-img img,
.partners-post picture img {
  width: 100%;
}

.newspaper-post__up {
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding-bottom: 4px;
  margin-bottom: 14px;
}

.newspaper-post__up .doc-line {
  position: relative;
  padding-bottom: 14px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.newspaper-post__up .doc-line::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 40px;
  background-color: #e37021;
}

.newspaper-post__date {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
}

.form-page__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .form-page__row {
    display: block;
    margin-bottom: 16px;
  }
}

.form-page__row-grid {
  display: grid;
  grid-template-columns: 1fr 1.1961fr;
  margin-bottom: 32px;
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
}

@media (max-width: 767px) {
  .form-page__row-grid {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }

  .form-page__wrap-text {
    margin-bottom: 8px;
  }

  .form-page__wrap-text:last-child {
    margin-bottom: 0;
  }
}

.form-page__caption {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  margin-right: 26px;
  margin-top: 12px;
  min-width: 174px;
  display: block;
}

@media (max-width: 767px) {
  .form-page__caption {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.form-page__caption span {
  font-size: 16px;
  line-height: 24px;
  color: #e37021;
}

.form-page__caption--change {
  margin-top: 0;
}

.form-page__caption--mb {
  margin-bottom: 24px;
}

.form-page__text {
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
}

.form-page__wrap {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.form-page__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin-bottom: 24px;
}

@media (max-width: 1023px) {
  .form-page__grid {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 32px;
    -moz-column-gap: 32px;
    column-gap: 32px;
    row-gap: 24px;
  }
}

@media (max-width: 767px) {
  .form-page__grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
    margin-bottom: 16px;
  }
}

.form-page .textarea {
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .form-page .textarea {
    margin-bottom: 16px;
  }
}

.form-page .file-field__wrapper {
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .form-page .file-field__label,
  .form-page .file-field__wrapper {
    margin-bottom: 16px;
  }
}

.form-page__down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 575px) {
  .form-page__down {
    display: block;
  }
}

.form-page__down .block-btn {
  border: 1px solid #e37021;
}

.form-page__down .block-btn:hover .block-btn__arrow {
  stroke: #e37021;
}

.field__circle-hint svg,
.form-page__down .block-btn,
.form-page__down .block-btn__arrow,
.form-page__down .block-btn__text {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .form-page__down .check-form {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .form-page__down .check-form {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

.field--grid {
  display: grid;
  grid-template-columns: 1fr 1.1961fr;
  margin-bottom: 32px;
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
}

@media (max-width: 767px) {
  .field--grid {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
}

.field--grid .field__description {
  margin-top: 12px;
}

.field__description {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  display: block;
  margin-bottom: 8px;
}

.field__description span {
  color: #e37021;
}

.field--sm .field__date {
  max-width: 424px;
}

.adrees-field__field,
.field__input {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.field__flex,
.field__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.field__flex .field__description {
  margin-bottom: 0;
  margin-right: 15px;
}

@media (max-width: 1023px) {
  .field__flex .field__description {
    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  .field__flex .field__description {
    margin-right: 8px;
    min-width: 19px;
  }
}

.field__row {
  margin-bottom: 8px;
}

.field__row .field__description {
  margin-bottom: 0;
}

.field__circle-hint {
  cursor: pointer;
  margin-left: 7px;
}

.field__circle-hint svg {
  fill: #e37021;
}

.field__date {
  position: relative;
  min-width: 144px;
}

.field__date .datetimepicker .arrow-month button::before,
.field__date .field::before {
  content: "";
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-position: center;
}

.field__date .field::before {
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
  /* background-image: url(../img/date-icon.svg); */
}

.field__date .datetimepicker .arrow-month svg,
.field__date .field .custom-button-effect,
.field__date .field.has-value::before {
  display: none;
}

.field__date .field .field-input,
.field__date .field .field-label {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
  font-family: "Arial", sans-serif;
}

.field__date .field .field-input {
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #d8dade;
  min-height: 48px;
  padding-left: 23px;
}

.field__date .field .field-input:hover {
  border: 1px solid #767d8b;
}

.field__date .field .field-label {
  left: 23px;
}

.field__date .datetimepicker .header-picker-year {
  opacity: 1;
}

.field__date .datetimepicker .arrow-month {
  width: 26px;
  -webkit-box-flex: 0 !important;
  -webkit-flex: none !important;
  -moz-box-flex: 0 !important;
  -ms-flex: none !important;
  flex: none !important;
}

.field__date .datetimepicker .arrow-month button {
  width: 16px;
  height: 10px;
  position: relative;
}

.field__date .datetimepicker .arrow-month button::before {
  position: absolute;
  /* background-image: url(../img/arrow-date.svg); */
  left: -webkit-calc(50% - 8px);
  left: -moz-calc(50% - 8px);
  left: calc(50% - 8px);
  bottom: 0;
  width: 16px;
  top: 0;
  background-repeat: no-repeat;
}

.field__date .datetimepicker .arrow-month .datepicker-prev {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.field__date .field.has-value .field-label {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.field__date .field.has-value .custom-button-content:hover span::after,
.field__date .field.has-value .custom-button-content:hover span::before {
  background-color: #0061aa;
}

.field__date .field.has-value .custom-button-content span {
  font-size: 0;
  position: relative;
  width: 26px;
  height: 26px;
}

.field__date .field.is-focused .field-input {
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 1px #767d8b;
  border: 1px solid #767d8b !important;
}

.field__date .field::before,
.field__date img {
  position: absolute;
  right: 17px;
  top: -webkit-calc(50% - 7.5px);
  top: -moz-calc(50% - 7.5px);
  top: calc(50% - 7.5px);
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.field__date .adrees-field__field,
.field__date .field__input {
  padding-right: 40px;
}

.field--error .adrees-field__field,
.field--error .field__field,
.field--error .field__input,
.field--error .topics-modal__field {
  border-color: #df0f0f;
  -webkit-box-shadow: 0 0 0 1px #df0f0f;
  -moz-box-shadow: 0 0 0 1px #df0f0f;
  box-shadow: 0 0 0 1px #df0f0f;
}

.subscription-block__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 22px;
}

.subscription-block__wrapper p {
  margin-bottom: 15px;
}

.subscription-block__wrapper .block-btn {
  -webkit-align-self: inherit;
  -ms-flex-item-align: inherit;
  align-self: inherit;
}

.subscription-block__text {
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  display: block;
  margin-bottom: 15px;
}

.subscription-block__down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.subscription-block__down .custom-select {
  width: auto;
}

.subscription-block__down .field__description {
  margin-bottom: 0;
  margin-right: 24px;
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  row-gap: 64px;
}

@media (max-width: 1500px) {
  .partners-grid {
    row-gap: 32px;
  }
}

@media (max-width: 767px) {
  .partners-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.partners-post__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1500px) {
  .partners-post__wrapper {
    display: block;
  }
}

.partners-post picture {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 120px;
  margin-right: 32px;
}

@media (max-width: 1500px) {
  .partners-post picture {
    margin-bottom: 16px;
  }
}

.partners-post__wrap-text {
  border-top: 1px solid rgba(58, 69, 88, 0.2);
  padding-top: 19px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ballon-caption::before,
.partners-post__wrap-text::before {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  background-color: #e37021;
}

.partners-post__wrap-text::before {
  top: 4px;
  width: 32px;
}

.partners-post__caption {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.partners-post:hover .partners-post__caption {
  color: #0061aa;
}

.map {
  width: 100%;
  height: 600px;
}

@media (max-width: 1500px) {
  .map {
    height: 536px;
  }
}

@media (max-width: 1359px) {
  .map {
    height: 480px;
  }
}

@media (max-width: 575px) {
  .map {
    height: 360px;
  }
}

.ballon-caption,
.ballon-tel {
  line-height: normal;
  color: #3a4558;
  margin-bottom: 15px;
}

.ballon-caption {
  padding-bottom: 10px;
  display: block;
  position: relative;
  font-size: 20px;
}

.ballon-caption::before {
  bottom: 0;
  width: 30px;
}

.ballon-tel {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.ballon-up {
  padding: 10px 10px 0;
}

.ballon-middle,
.ballon-text {
  padding: 0 10px;
  margin-bottom: 10px;
}

.ballon-down {
  padding: 0 10px 14px;
}

.branches__grid-sm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: 40px;
}

.branches__grid-sm--border {
  border-top: 1px solid rgba(58, 69, 88, 0.2);
  padding-top: 47px;
}

.contact-information__down {
  display: flex;
  justify-content: center;
}

@media (max-width: 1500px) {
  .branches__grid-sm {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
}

.branches__grid-sm--change {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1023px) {
  .branches__grid-sm--change {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .branches__grid-sm--change {
    padding-top: 31px;
  }
}

.branches__grid-sm .doc-line {
  margin-bottom: 0;
}

.branches__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
}

@media (max-width: 1500px) {
  .branches__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .branches__grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }
}

.post-branches {
  position: relative;
  padding: 36px 40px;
}

@media (max-width: 575px) {
  .post-branches {
    padding: 20px 16px;
  }
}

.post-branches::before {
  content: "";
  position: absolute;
  border: 1px solid rgba(58, 69, 88, 0.2);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.post-branches:hover .post-branches__wrap-arrow {
  width: 38px;
}

.post-branches:hover .post-branches__title {
  color: #0061aa;
}

.post-branches:hover::before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}

.post-branches__up {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  padding-bottom: 17px;
  margin-bottom: 26px;
  position: relative;
  z-index: 1;
}

@media (max-width: 575px) {
  .post-branches__up {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.post-branches__up:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.post-branches__title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 575px) {
  .post-branches__title {
    font-size: 18px;
    line-height: 24px;
  }
}

.post-branches__wrap-arrow {
  position: relative;
  width: 22px;
  height: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-left: 15px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.post-branches__arrow {
  width: 48px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 575px) {
  .post-branches__list {
    display: none;
  }
}

.post-branches__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.post-branches__item:last-child {
  margin-bottom: 0;
}

.post-branches__icon {
  width: 18px;
  height: 18px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: 18px;
  margin-right: 13px;
}

.post-branches__text {
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
}

.contact-information__up {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 38px;
}

.contact-information .contacts-row {
  margin-bottom: 0;
}

.contact-information__icon {
  width: 36px;
  margin-right: 43px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.contact-information__text {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
  display: block;
  margin-right: 22px;
}

.contact-information__tel {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
}

@media (max-width: 1023px) {
  .contact-information__tel {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .contact-information__tel {
    font-size: 24px;
  }
}

.contact-information__grid-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .contact-information__grid-text {
    grid-template-columns: 1fr;
  }
}

.contact-information__row {
  margin-bottom: 62px;
}

@media (max-width: 767px) {
  .contact-information__row {
    margin-bottom: 37px;
  }
}

.contact-information__row .block-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.contact-information__row .block-btn__svg {
  max-width: 46px;
  max-height: 44px;
  margin: 0 25px 0 0;
}

.contact-information__row .block-btn__text {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.contact-information__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-bottom: 62px;
}

@media (max-width: 767px) {
  .contact-information__grid {
    grid-template-columns: 1fr;
    grid-gap: 22px;
    margin-bottom: 37px;
  }
}

@media (max-width: 1023px) {
  .contact-information .title {
    font-size: 32px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .contact-information .title {
    font-size: 24px;
  }
}

.drive-through__caption {
  position: relative;
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  display: block;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .drive-through__caption {
    font-size: 18px;
  }
}

.drive-through__caption::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 24px;
  background-color: #e37021;
}

.drive-through__text {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
  display: block;
  margin-bottom: 24px;
}

.drive-through__map {
  width: 100%;
  height: 400px;
}

@media (max-width: 1359px) {
  .drive-through__map {
    height: 280px;
  }
}

.guest-pass__wrapper {
  display: grid;
  grid-template-areas: "img text" "img form";
  grid-template-columns: 424px 1fr;
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
}

@media (max-width: 1500px) {
  .guest-pass__wrapper {
    grid-template-columns: 416px 1fr;
  }
}

@media (max-width: 1359px) {
  .guest-pass__wrapper {
    grid-template-columns: 288px 1fr;
  }
}

@media (max-width: 1023px) {
  .guest-pass__wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "img text" "form form";
    row-gap: 35px;
  }
}

@media (max-width: 767px) {
  .guest-pass__wrapper {
    grid-template-columns: 1fr;
    grid-template-areas: "img" "text" "form";
    row-gap: 25px;
  }
}

.guest-pass__wrap-img {
  grid-area: img;
}

@media (max-width: 767px) {
  .guest-pass__wrap-img {
    max-width: 240px;
    margin: 0 auto;
  }
}

.guest-pass__wrap-img img,
.guest-pass__wrap-img picture,
.position-inner__img img {
  width: 100%;
}

.guest-pass__wrap-text {
  grid-area: text;
}

.guest-pass__wrap-text .text-area p:last-child {
  margin-bottom: 32px;
}

.guest-pass__form {
  grid-area: form;
}

.guest-pass__text {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
  display: block;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .guest-pass__text:last-child {
    margin-bottom: 0;
  }
}

.guest-pass__caption {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  position: relative;
  padding-bottom: 13px;
  margin-bottom: 20px;
}

@media (max-width: 1023px) {
  .guest-pass__caption {
    font-size: 32px;
  }
}

.guest-pass__caption::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 40px;
  background-color: #e37021;
}

.guest-pass__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  row-gap: 24px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .guest-pass__grid {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
}

.guest-pass .check-form {
  margin-bottom: 32px;
}

.guest-pass .block-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.position-inner {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .position-inner {
    margin-bottom: 30px;
  }
}

.position-inner__up {
  margin-bottom: 38px;
}

@media (max-width: 767px) {
  .position-inner__up {
    margin-bottom: 30px;
  }
}

.position-inner__title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
}

@media (max-width: 1359px) {
  .position-inner__title {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .position-inner__title {
    font-size: 32px;
    line-height: 40px;
  }
}

.position-inner__subtitle {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
}

.position-inner__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .position-inner__wrapper {
    display: block;
  }
}

.position-inner__wrap-img {
  max-width: 424px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 32px;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@media (max-width: 1500px) {
  .position-inner__wrap-img {
    max-width: 416px;
  }
}

@media (max-width: 1359px) {
  .position-inner__wrap-img {
    max-width: 288px;
  }
}

@media (max-width: 1023px) {
  .position-inner__wrap-img {
    max-width: 224px;
  }
}

@media (max-width: 767px) {
  .position-inner__wrap-img {
    max-width: 216px;
    margin: 0 auto 22px;
  }
}

.position-inner__wrap-text p {
  margin-bottom: 22px;
}

.wrap-table {
  margin-bottom: 32px;
}

.contacts-block:last-child,
.wrap-table:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .wrap-table {
    overflow: auto;
  }

  .wrap-table table {
    width: 1336px;
  }
}

.contacts-block {
  margin-bottom: 54px;
}

.company-details .up-row {
  margin-bottom: 20px;
}

.company-details__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

@media (max-width: 767px) {
  .company-details__grid {
    display: block;
  }
}

.company-details__wrap h3,
.company-details__wrap h4 {
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  font-weight: 400;
  display: block;
  margin-bottom: 20px;
}

.company-details__wrap ul li {
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
  margin-bottom: 28px;
}

.page-search__row {
  max-width: 66.049%;
  margin: 0 auto 53px;
}

@media (max-width: 1023px) {
  .page-search__row {
    max-width: 100%;
    margin-bottom: 22px;
  }
}

.page-search .search-line {
  position: static;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  opacity: 1;
  margin-bottom: 10px;
}

.page-search .search-line input {
  border: 1px solid #d8dade;
  border-right: none;
}

.page-search__description,
.text-value {
  font-size: 16px;
  line-height: normal;
  color: #767d8b;
}

.text-value {
  font-family: "Oswald", sans-serif;
  display: block;
  margin-bottom: 35px;
}

.search-value-block {
  margin-bottom: 33px;
}

.search-value-block:last-child,
.search-value-block__item:last-child {
  margin-bottom: 0;
}

.search-value-block__item {
  position: relative;
  margin-bottom: 46px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.search-value-block__number {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #3a4558;
  min-width: 16px;
  margin-right: 147px;
  margin-top: 2px;
}

@media (max-width: 1023px) {
  .search-value-block__number {
    margin-right: 66px;
  }
}

.search-value-block__caption-text {
  font-size: 18px;
  line-height: 26px;
  color: #3a4558;
  display: block;
  position: relative;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  margin-bottom: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-value-block__caption-text::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 40px;
  height: 4px;
  background-color: #e37021;
}

.search-value-block__caption-text:hover,
.search-value-block__link:hover {
  color: #0061aa;
}

.search-value-block__text-info {
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
  display: block;
  margin-bottom: 7px;
}

.search-value-block__link {
  display: inline;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a4558;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-value-block__link::after {
  content: "/";
  margin: 0 8px;
}

.search-value-block__link:last-child,
.search-value-block__link:last-child:hover {
  color: #767d8b;
}

.search-value-block__link:last-child::after {
  display: none;
}

.not-found .first-screen__block {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.subtitle {
  font-size: 20px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-bottom: 24px;
}

.map-site {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
}

@media (max-width: 1024px) {
  .map-site {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
  }
}

@media (max-width: 767px) {
  .map-site {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 16px;
  }
}

.map-site__caption {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  display: inline-block;
  margin-bottom: 14px;
  padding-bottom: 4px;
  position: relative;
}

.map-site__caption::before {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  bottom: 0;
  right: 100%;
  background-color: #e37021;
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  -moz-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

.map-site__caption:hover::before {
  right: 0;
}

.map-site__caption:last-child {
  margin-bottom: 0;
}

.map-site__item {
  margin-bottom: 20px;
}

.map-site__link {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.map-site__wrap-arrow {
  width: 14px;
  min-width: 14px;
  height: 10px;
  overflow: hidden;
  position: relative;
  margin: 4px 17px 0 0;
}

.map-site__arrow {
  width: 48px;
  position: absolute;
  top: 0;
  right: 0;
  max-height: 10px;
  stroke: #e37021;
}

.map-site__text {
  font-size: 16px;
  line-height: normal;
  color: #3a4558;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.map-site__text:active,
.map-site__text:hover {
  color: #0061aa;
  text-decoration: underline;
}

.map-site__sub-list {
  margin-left: 31px;
  margin-top: 20px;
}

.map-site__sub-item {
  margin-bottom: 20px;
}

.map-site__sub-item:last-child {
  margin-bottom: 0;
}

.map-site__sub-link {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.map-site__line-icon {
  width: 14px;
  background-color: #e37021;
  height: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-top: 6px;
  margin-right: 15px;
}

.map-site__sub-text {
  font-size: 14px;
  line-height: normal;
  color: #767d8b;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.map-site__sub-text:active,
.map-site__sub-text:hover {
  color: #0061aa;
  text-decoration: underline;
}

.leadership {
  margin-top: -136px;
}

.positions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  row-gap: 56px;
  margin-bottom: 56px;
}

.positions-post {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.positions-post:hover .positions-post__wrap-image picture,
.positions-post:hover .positions-post__wrap-image::before {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.positions-post:hover .positions-post__name,
.positions-post:hover .positions-post__name-two {
  color: #0061aa;
}

.positions-post,
.positions-post__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.positions-post__wrapper .positions-post__up {
  margin-right: 32px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.positions-post__wrapper .positions-post__down {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.positions-post__wrapper .positions-post__wrap-image {
  min-width: 272px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.positions-post__wrapper .positions-post__wrap-image picture img {
  width: 100%;
  max-width: inherit;
}

@media (max-width: 1500px) {
  .positions-post__wrapper .positions-post__wrap-image {
    min-width: 192px;
    min-height: 192px;
  }
}

@media (max-width: 1359px) {
  .positions-post__wrapper .positions-post__wrap-image {
    min-width: 128px;
    min-height: 128px;
  }

  .positions-post__wrapper .positions-post__wrap-image img {
    width: 55px;
  }
}

@media (max-width: 575px) {
  .positions-post__wrapper .positions-post__wrap-image {
    min-width: 152px;
    min-height: 152px;
    max-width: inherit;
    width: auto;
  }
}

.positions-post__wrapper .positions-post__name {
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 575px) {
  .positions-post__wrapper .positions-post__name {
    font-size: 18px;
    line-height: 24px;
  }

  .positions-post__wrapper {
    display: block;
  }
}

.positions-post__up {
  margin-bottom: 21px;
}

.positions-post picture,
.positions-post__img {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
}

.positions-post picture {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.positions-post__img {
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.positions-post__down {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.positions-post__row-name {
  padding-bottom: 20px;
  position: relative;
  border-bottom: 1px solid rgba(58, 69, 88, 0.2);
  margin-bottom: 13px;
}

.positions-post__row-name::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 40px;
  height: 4px;
  background-color: #e37021;
}

.positions-post__name,
.positions-post__name-two {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.positions-post__wrap-text {
  margin-bottom: 6px;
}

.positions-post__wrap-text span:last-child,
.positions-post__wrap-text:last-child {
  margin-bottom: 0;
}

.positions-post__wrap-text span {
  font-size: 16px;
  line-height: 24px;
  color: rgba(58, 69, 88, 0.7);
  display: block;
  margin-bottom: 4px;
}

.positions-post__tel {
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  color: #3a4558;
}

.positions-post__tel:hover {
  color: #3a4558;
}

.positions-post__email {
  font-size: 16px;
  line-height: normal;
  color: #0061aa;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.positions-post__email:hover {
  color: #e37021;
}

.positions-post__wrap-image,
.positions-post__wrap-image::before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.positions-post__wrap-image {
  max-width: 272px;
  width: 100%;
  min-height: 272px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.positions-post__wrap-image picture {
  max-width: 100%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.positions-post__wrap-image picture img {
  max-width: 100%;
  width: 272px;
}

.positions-post__wrap-image img {
  position: relative;
  z-index: 1;
  width: 88px;
}

@media (max-width: 1500px) {
  .positions-post__wrap-image {
    max-width: 192px;
    min-height: 192px;
  }

  .positions-post__wrap-image img {
    width: 55px;
  }
}

@media (max-width: 1359px) {
  .positions-post__wrap-image {
    max-width: 208px;
    min-height: 208px;
  }
}

@media (max-width: 1023px) {
  .positions-post__wrap-image {
    max-width: 160px;
    min-height: 160px;
  }
}

@media (max-width: 575px) {
  .positions-post__wrap-image {
    max-width: 152px;
    min-height: 152px;
  }
}

.page-header--change .logo,
.positions-post__wrap-image--change::before {
  display: none;
}

.positions-post__wrap-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid rgba(58, 69, 88, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

.text-line {
  margin-bottom: 40px;
}

.text-line h3,
.text-line h4 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.text-line h3 {
  display: block;
  line-height: 48px;
  color: #3a4558;
  margin-bottom: 15px;
  font-size: 40px;
}

.text-line h4,
.text-line p {
  line-height: 32px;
  color: #3a4558;
}

.text-line h4 {
  font-size: 24px;
}

.text-line p {
  font-size: 20px;
}

.text-line--change {
  margin-bottom: 24px;
}

.leadership-end-section {
  padding-top: 56px;
  border-top: 1px solid rgba(58, 69, 88, 0.2);
}

.page-header {
  position: absolute;
  z-index: 50;
  width: 100%;
}

.page-header--change {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
}

.page-header--change .logo--inner-mob,
.text-line p {
  display: block;
}

.page-header--change .page-header__up .page-header__flex {
  border-color: #d8dade;
}

.page-header--change .page-header__text {
  color: #767d8b;
}

.page-header--change .navigation__link,
.page-header--change .navigation__link:hover,
.page-header--change .page-header__tel,
.page-header--change .page-header__tel:hover,
.page-header--change .search-block__text,
.page-header--change .wrap-link__text {
  color: #3a4558;
}

.page-header--change .search-block:hover .search-block__text,
.page-header--change .wrap-link:hover .wrap-link__text {
  color: #fff;
}

@media (max-width: 1360px) {
  .page-header--change .wrap-link::before {
    background-color: #d8dade;
  }
}

.page-header--change .navigation__link {
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
}

.page-header--change .search-line__flex input {
  border: 1px solid #d8dade;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.page-header--change .search-line__close span,
.page-header--change .search-line__text {
  color: #3a4558;
}

.page-header__up .page-header__flex {
  padding: 25px 0 27px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.page-header__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-header__left,
.page-header__left .wrap-logo {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.page-header__left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 575px) {
  .page-header__left {
    -webkit-flex-shrink: inherit;
    -ms-flex-negative: inherit;
    flex-shrink: inherit;
  }
}

.page-header__left .wrap-logo {
  margin-right: 30px;
}

@media (max-width: 1023px) {
  .page-header__left .wrap-logo {
    margin-right: 24px;
  }
}

@media (max-width: 575px) {
  .page-header__left .wrap-logo {
    margin-right: 16px;
  }
}

.page-header__left .logo {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

@media (max-width: 359px) {
  .page-header__left .logo {
    max-width: 180px;
  }
}

.page-header__block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1023px) {
  .page-header__block {
    display: block;
  }
}

.page-header__wrap {
  margin-right: 32px;
}

.page-header__wrap:last-child {
  margin-right: 0;
}

.page-header__text {
  font-size: 12px;
  line-height: normal;
  color: #fff;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  white-space: nowrap;
  display: block;
  margin-bottom: 2px;
}

.page-header__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-header .circle-hint {
  margin-right: 8px;
}

.page-header__tel {
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  display: block;
}

.page-header__tel:hover {
  color: #fff;
}

.page-header__tel:focus {
  color: #fff;
}

.page-header__right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.page-header__right .social-list {
  margin-left: 15px;
}

.page-header__down {
  padding: 15px 0;
  position: relative;
}

.page-header__left-block {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-header__left-block,
.page-header__right-block,
.wrap-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.wrap-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

@media (max-width: 1500px) {
  .wrap-link {
    max-width: 188px;
  }
}

.wrap-link::before,
.wrap-link__icon {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.wrap-link::before {
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: absolute;
}

.search-line__flex button:hover,
.wrap-link:hover::before {
  background-color: #e37021;
}

.wrap-link:hover .wrap-link__icon {
  stroke: #fff;
}

.wrap-link__wrap-icon {
  max-width: 24px;
  max-height: 24px;
  margin-right: 17px;
}

.wrap-link__icon {
  position: relative;
  z-index: 1;
  stroke: #e37021;
}

.wrap-link__text {
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  line-height: normal;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

.logo-two,
.logo-two img {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.logo-two {
  overflow: hidden;
  width: 0;
}

.logo-two img {
  max-width: 272px;
}

.navigation {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.navigation__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -37px;
}

.navigation__item {
  padding: 0 37px;
  position: relative;
}

.navigation__wrap:hover .navigation__link::before {
  right: 0;
}

.navigation__wrap:hover .nav-menu {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  pointer-events: unset;
}

.navigation__link {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  padding-bottom: 10px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation__link::before {
  position: absolute;
  content: "";
  left: 0;
  right: 100%;
  height: 4px;
  bottom: 4px;
  background-color: #e37021;
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  -moz-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

.navigation__link:hover,
.page-footer__text b {
  color: #fff;
}

.navigation__link:focus {
  color: #fff;
}

.nav-menu {
  position: absolute;
  left: 37px;
  top: 100%;
  min-width: 352px;
  width: auto;
  display: inline-block;
  background-color: #fff;
  padding: 16px 24px;
  -webkit-border-radius: 0 4px 4px 4px;
  -moz-border-radius: 0 4px 4px 4px;
  border-radius: 0 4px 4px 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0;
  pointer-events: none;
}

.nav-menu__item:first-child .nav-menu__link {
  border-top: 1px solid transparent;
}

.nav-menu__item:first-child .nav-menu__link:hover {
  border-top: 1px solid #d8dade;
}

.nav-menu__item--active + .nav-menu__item .nav-menu__link,
.nav-menu__item:hover + .nav-menu__item .nav-menu__link {
  border-top-color: transparent;
}

.nav-menu__item--active + .nav-menu__item .nav-menu__link:hover {
  border: 1px solid #d8dade;
}

.nav-menu__item--active .nav-menu__link {
  background-color: #0061aa;
  border-color: #0061aa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.nav-menu__item--active .nav-menu__link:hover {
  border-color: #0061aa;
}

.nav-menu__item--active .nav-menu__text {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
  color: #fff;
}

.nav-menu__item--active .nav-menu__wrap-arrow {
  display: none;
}

.nav-menu__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid transparent;
  border-top: 1px dashed rgba(58, 69, 88, 0.2);
  padding: 11px 7px;
}

.nav-menu__link:hover {
  border: 1px solid #d8dade;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.nav-menu__link:hover .nav-menu__text {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}

.nav-menu__link:hover .nav-menu__wrap-arrow {
  width: 40px;
  -webkit-transform: translateX(18px);
  -moz-transform: translateX(18px);
  -ms-transform: translateX(18px);
  -o-transform: translateX(18px);
  transform: translateX(18px);
}

.nav-menu__link,
.nav-menu__text,
.nav-menu__wrap-arrow {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav-menu__text {
  font-size: 16px;
  line-height: normal;
  font-family: "Oswald", sans-serif;
  max-width: 80%;
}

.nav-menu__wrap-arrow {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 16px;
  min-width: 16px;
}

.nav-menu__arrow {
  stroke: #e37021;
  width: 40px;
  height: 10px;
}

.search-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 6px 0;
}

.search-block::before,
.search-block__icon {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-block::before {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: absolute;
}

@media (min-width: 1024px) {
  .search-block:hover::before {
    background-color: #e37021;
    left: -17px;
    right: -17px;
  }

  .search-block:hover .search-block__icon {
    stroke: #fff;
  }
}

.search-block__icon {
  max-width: 24px;
  min-width: 24px;
  margin-right: 9px;
  position: relative;
  z-index: 1;
  stroke: #e37021;
}

.search-block__text {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  z-index: 1;
}

.burger,
.mob-menu {
  display: none;
}

.search-line {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.search-line--open {
  left: 0;
  opacity: 1;
}

.search-line__flex input,
.search-line__form {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.search-line__close,
.search-line__flex,
.search-line__flex button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.search-line__flex {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.search-line__flex input {
  background-color: #fff;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  padding: 0 20px;
  min-width: 1px;
}

.search-line__close,
.search-line__flex button {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.search-line__flex button {
  min-width: 96px;
  background-color: #0061aa;
  padding: 9px 15px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-line__close span,
.search-line__flex button span,
.search-line__text {
  font-weight: 700;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  white-space: nowrap;
}

.search-line__close span,
.search-line__flex button span {
  margin-left: 8px;
  font-size: 10px;
  line-height: 24px;
}

.search-line__text {
  font-size: 12px;
  line-height: 16px;
  margin-right: 15px;
}

.search-line__close {
  margin-left: 15px;
}

.search-line__close div {
  width: 28px;
  height: 28px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 0;
  line-height: 0;
}

.search-line__close div::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  left: -webkit-calc(50% - 1px);
  left: -moz-calc(50% - 1px);
  left: calc(50% - 1px);
  background-color: #e37021;
}

.search-line__close div::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  top: -webkit-calc(50% - 1px);
  top: -moz-calc(50% - 1px);
  top: calc(50% - 1px);
  background-color: #e37021;
}

.search-line__close span {
  margin-left: 12px;
  display: block;
}

.page-header__down .page-header__flex {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.page-header.hide-line .page-header__down .page-header__flex {
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.header-inner--change.header-inner--offset {
  min-height: 166px;
}

@media (max-width: 1024px) {
  .header-inner--change.header-inner--offset {
    min-height: 97px;
  }
}

.page-footer {
  background-color: #4b5568;
  background-image: url(../img/line-bg.svg);
  padding: 78px 0;
}

.page-footer__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
}

.page-footer__down {
  padding: 97px 0 0;
}

.page-footer__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.page-footer__flex .footer-link-kompany {
  width: 70%;
}

.page-footer__flex .social {
  width: 30%;
}

.page-footer__flex .footer-wrap-link {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.page-footer__text {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #b2bbcb;
  text-align: end;
}

.caption-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.caption-row__caption {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 6px;
}

.caption-row__caption::before {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  bottom: 0;
  right: 100%;
  background-color: #e37021;
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  -moz-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

.caption-row__caption:hover {
  color: #fff;
}

.caption-row__caption:hover::before {
  right: 0;
}

.caption-row__caption:focus {
  color: #fff;
}

.caption-row__caption:focus::before {
  right: 0;
}

.caption-row__caption:active,
.footer-nav-list__link:hover .footer-nav-list__text {
  color: #fff;
}

.caption-row__caption:active::before,
.footer-link-kompany__link span:hover::before {
  right: 0;
}

.footer-nav {
  margin-bottom: 3px;
}

.footer-nav-list {
  overflow: hidden;
}

.footer-nav-list__item {
  margin-bottom: 20px;
}

.footer-nav-list__item:last-child {
  margin-bottom: 0;
}

.footer-nav-list__link {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transform: translateX(-23px);
  -moz-transform: translateX(-23px);
  -ms-transform: translateX(-23px);
  -o-transform: translateX(-23px);
  transform: translateX(-23px);
}

.footer-nav-list__link:hover {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.footer-nav-list__wrap-arrow {
  width: 16px;
  min-width: 16px;
  height: 10px;
  overflow: hidden;
  position: relative;
  margin: 4px 7px 0 0;
}

.footer-nav-list__arrow {
  width: 48px;
  position: absolute;
  top: 0;
  right: 0;
  max-height: 10px;
  stroke: #e37021;
}

.footer-nav-list__text {
  font-size: 14px;
  line-height: normal;
  color: #b2bbcb;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.footer-link-kompany {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 37px;
}

.footer-link-kompany__logo {
  max-width: 48px;
  min-width: 48px;
  margin-right: 26px;
}

.footer-link-kompany__link {
  margin-top: 7px;
  padding-right: 25px;
  margin-right: 25px;
  position: relative;
}

.footer-link-kompany__link span {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

.footer-link-kompany__link span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 100%;
  bottom: -2px;
  height: 2px;
  background-color: #e37021;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footer-link-kompany__link:before {
  content: "";
  position: absolute;
  top: -webkit-calc(50% - 16px);
  top: -moz-calc(50% - 16px);
  top: calc(50% - 16px);
  right: 0;
  height: 32px;
  width: 1px;
  background-color: #fff;
  opacity: 0.2;
}

.footer-link-kompany__link:last-child {
  padding: 0;
  margin-right: 0;
}

.footer-link-kompany__link:last-child::before {
  display: none;
}

.social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 37px;
}

.social__text {
  font-size: 12px;
  line-height: 24px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  margin-right: 16px;
}

.social__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0 -4px;
}

.social__item {
  padding: 0 4px;
}

.social__link,
.social__link::before {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background-color: #0061aa;
}

.social__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.cooperate__link::before,
.social__link::before {
  content: "";
  position: absolute;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social__link::before {
  top: 0;
  right: 0;
  bottom: 0;
}

.social__link:hover::before {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  background-color: #e37021;
}

.social__icon {
  position: relative;
  z-index: 1;
}

.cooperate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cooperate__logo {
  max-width: 32px;
  min-width: 32px;
  margin-right: 16px;
}

.cooperate__text {
  font-size: 14px;
  line-height: 32px;
  color: #b2bbcb;
  margin-right: 4px;
}

.cooperate__link {
  font-size: 14px;
  line-height: 32px;
  color: #fff;
  position: relative;
}

.cooperate__link::before {
  right: 100%;
  bottom: 2px;
  height: 2px;
  background-color: #e37021;
}

.cooperate__link:hover {
  color: #fff;
}

.cooperate__link:hover::before {
  right: 0;
}

.cooperate__link:focus {
  color: #fff;
}

.cooperate__link:active {
  color: #fff;
}

.footer-wrap-link,
.footer-wrap-link__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-wrap-link {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -moz-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer-wrap-link__row {
  padding-right: 28px;
  margin-right: 24px;
  position: relative;
}

.footer-wrap-link__row::before {
  content: "";
  position: absolute;
  right: 0;
  top: -webkit-calc(50% - 8px);
  top: -moz-calc(50% - 8px);
  top: calc(50% - 8px);
  width: 1px;
  height: 16px;
  background-color: #fff;
  opacity: 0.2;
}

.footer-wrap-link__row:last-child {
  padding-right: 0;
  margin-right: 0;
}

.footer-wrap-link__row:last-child::before {
  display: none;
}

.footer-wrap-link__row:hover .footer-wrap-link__wrap-arrow {
  width: 22px;
  margin-left: -6px;
}

.footer-wrap-link__wrap-arrow {
  position: relative;
  width: 16px;
  height: 10px;
  overflow: hidden;
  margin-top: 3px;
  margin-right: 9px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footer-wrap-link__text {
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #fff;
}

.first-screen {
  position: relative;
}

.first-screen__wrap {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 100%;
}

.first-screen__wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(1, 31, 84, 0.4)),
    to(rgba(1, 31, 84, 0.4))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.4),
    rgba(1, 31, 84, 0.4)
  );
  background: -moz-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.4),
    rgba(1, 31, 84, 0.4)
  );
  background: -o-linear-gradient(
    bottom,
    rgba(1, 31, 84, 0.4),
    rgba(1, 31, 84, 0.4)
  );
  background: linear-gradient(0deg, rgba(1, 31, 84, 0.4), rgba(1, 31, 84, 0.4));
  z-index: 10;
}

.first-screen__wrap .container {
  position: static;
}

.first-screen__block {
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 100px 0;
}

.first-screen__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.first-screen__img {
  max-width: 66.5%;
  position: relative;
  z-index: 11;
}

.first-screen__navigation {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.first-screen__navigation-wrap {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.first-screen__arrow,
.first-screen__navigation-wrap,
.first-screen__wrap-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.first-screen__wrap-arrow {
  margin-right: 32px;
}

.first-screen__arrow {
  background: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.first-screen__arrow--prev {
  margin-right: 16px;
}

.first-screen__arrow--next {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.first-screen__arrow:hover .first-screen__wrap-svg {
  width: 42px;
}

.first-screen__wrap-svg {
  overflow: hidden;
  width: 24px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.first-screen__wrap-svg svg {
  width: 42px;
  height: 10px;
  stroke: #0061aa;
}

.first-screen__pagination,
.first-screen__pagination span {
  font-size: 12px;
  line-height: 24px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
}

.modal-container,
.overlay_popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 120;
  display: none;
}

.overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  -moz-transition: 0.3s all;
  transition: 0.3s all;
}

.modal.active,
.overlay.active {
  opacity: 1;
  visibility: visible;
  display: block;
}

.modal-container {
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container__container {
  display: table;
  width: 100%;
  height: 100%;
}

.modal-container__wrapper {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  padding: 30px 24px;
  text-align: center;
}

@media (max-width: 767px) {
  .modal-container__wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.overlay_popup {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.my-modal {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  padding: 48px;
  -webkit-box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  max-width: 672px;
}

@media (max-width: 767px) {
  .my-modal {
    padding: 24px;
  }
}

.my-modal__block {
  position: relative;
}

.my-modal__close {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 16px;
  top: 16px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.file-field__delete::after,
.file-field__delete::before,
.my-modal__close::after,
.my-modal__close::before {
  content: "";
  position: absolute;
  top: 4px;
  right: -webkit-calc(50% - 0.5px);
  right: -moz-calc(50% - 0.5px);
  right: calc(50% - 0.5px);
  bottom: 4px;
  width: 2px;
  background-color: #e37021;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.file-field__delete::after,
.my-modal__close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.file-field__delete:hover::after,
.file-field__delete:hover::before,
.my-modal__close:hover::after,
.my-modal__close:hover::before {
  background-color: #0061aa;
}

.my-modal .title,
.my-modal__up {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .my-modal .title {
    font-size: 24px;
    line-height: normal;
  }
}

.my-modal__up .title {
  margin-bottom: 15px;
  padding: 0;
}

.my-modal__up .title::before {
  display: none;
}

.my-modal__subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  opacity: 0.7;
}

.my-modal .field,
.my-modal__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 22px;
}

@media (max-width: 767px) {
  .my-modal .field {
    display: block;
  }
}

.my-modal .field__description {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  min-width: 120px;
  max-width: 120px;
  margin-right: 32px;
  display: block;
  margin-top: 13px;
}

@media (max-width: 767px) {
  .my-modal .field__description {
    margin-right: 0;
    margin-bottom: 12px;
  }
}

.my-modal .field__wrap {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}

.my-modal .field__field,
.my-modal .topics-modal__field {
  margin-bottom: 0;
  min-width: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.my-modal .field--error .adrees-field__field,
.my-modal .field--error .field__field,
.my-modal .field--error .field__input,
.my-modal .field--error .topics-modal__field {
  border-color: #df0f0f;
  -webkit-box-shadow: 0 0 0 1px #df0f0f;
  -moz-box-shadow: 0 0 0 1px #df0f0f;
  box-shadow: 0 0 0 1px #df0f0f;
}

.my-modal__flex {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .my-modal__flex {
    display: block;
    margin-bottom: 20px;
  }
}

.my-modal__text-info {
  font-size: 20px;
  line-height: 32px;
  color: #3a4558;
  display: block;
  padding-top: 25px;
}

.my-modal__link {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #0061aa;
}

.my-modal__link:hover {
  color: #0061aa;
  text-decoration: underline;
}

.my-modal__link:active,
.my-modal__link:focus {
  color: #0061aa;
}

.my-modal .link-more,
.my-modal .link-row {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 16px;
}

.my-modal .block-btn:last-child,
.my-modal .link-more:last-child,
.my-modal .link-row:last-child {
  margin-bottom: 0;
}

.my-modal .link-more__svg {
  stroke: #e37021;
  width: 14px;
  height: 14px;
  margin-left: 16px;
}

.my-modal .block-btn {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px;
}

.my-modal__caption {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  margin-bottom: 10px;
}

.my-modal__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}

.my-modal--two {
  max-width: 976px;
}

.my-modal__wrap-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 30px;
}

.my-modal__wrap-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline-width: 0;
}

.check-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
}

@media (max-width: 767px) {
  .check-form {
    margin-bottom: 20px;
  }

  .check-form:last-child {
    margin-bottom: 0;
  }
}

.check-form__input,
.topics-modal__wrap input {
  display: none;
}

.check-form__square,
.topics-modal__square {
  border: 1px solid #d8dade;
  -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.check-form__square:hover,
.topics-modal__square:hover {
  border: 1px solid #767d8b;
}

.check-form__input:checked + .check-form__square::before,
.check-form__input:checked + .topics-modal__square::before {
  position: absolute;
  content: "";
  left: 4px;
  top: 4px;
  bottom: 4px;
  right: 4px;
  background-color: #e37021;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.check-form__text,
.check-form__text a {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
}

.check-form__text a {
  color: #0061aa;
}

.check-form__text a:hover {
  text-decoration: underline;
  color: #0061aa;
}

.check-form__text a:active,
.check-form__text a:focus {
  color: #0061aa;
}

.check-form--disabled {
  position: relative;
}

.check-form--disabled::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.check-form--disabled .check-form__square,
.check-form--disabled .topics-modal__square {
  background-color: rgba(0, 0, 0, 0.2);
}

.topics-modal__flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 38px;
}

@media (max-width: 767px) {
  .topics-modal__flex {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .topics-modal__flex .block-btn {
    padding: 12px;
  }

  .topics-modal__flex .block-btn__text {
    display: none;
  }
}

.topics-modal__text-field {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3a4558;
  white-space: nowrap;
  margin-right: 16px;
}

@media (max-width: 767px) {
  .topics-modal__text-field {
    width: 100%;
    display: block;
    margin: 0 0 16px;
  }
}

.topics-modal__field {
  margin-right: 16px;
}

@media (max-width: 767px) {
  .topics-modal__field {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

.topics-modal .block-btn {
  position: relative;
  width: auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.topics-modal .block-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #0061aa;
  top: 0;
  right: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.topics-modal .block-btn:hover::before {
  background-color: #e37021;
}

.topics-modal .block-btn__svg {
  stroke: #fff;
  width: 16px;
  height: 16px;
  margin-left: 14px;
  position: relative;
  z-index: 1;
}

.topics-modal .block-btn__text {
  position: relative;
  z-index: 1;
}

.topics-modal__scroll-wrap {
  overflow: auto;
  margin-bottom: 32px;
}

.topics-modal__scroll-wrap .scrollbar-track {
  opacity: 1;
  background-color: transparent;
}

.topics-modal__scroll-wrap .scrollbar-track .scrollbar-thumb {
  background-color: #c4c4c4;
}

.topics-modal__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  row-gap: 14px;
  max-height: 300px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .topics-modal__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.topics-modal__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.topics-modal__text-check {
  font-size: 16px;
  line-height: 24px;
  color: #3a4558;
  margin-top: 1px;
}

.topics-modal__text-check span {
  font-size: 16px;
  line-height: 24px;
  color: #767d8b;
  margin-left: 5px;
}

.topics-modal__wrap-active {
  margin: 0 -4px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.topics-modal__down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.topics-modal__down .block-btn {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 8px;
}

.topics-modal__down .block-btn::before {
  border: 1px solid #e37021;
  background-color: transparent;
}

.topics-modal__down .block-btn:hover::before {
  background-color: #fff;
}

.topics-modal__down .block-btn:hover .block-btn__text {
  color: #e37021;
}

.topics-modal__down .block-btn:hover .block-btn__arrow {
  stroke: #e37021;
}

.topics-modal__down .topic-line {
  display: inline-block;
}

@media (max-width: 767px) {
  .topics-modal__down {
    display: block;
  }

  .topics-modal__down .block-btn {
    margin-bottom: 0 !important;
    width: 100%;
  }
}

.topics-modal__wrap input:checked + .topics-modal__square::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  bottom: 3px;
  right: 3px;
  background-color: #e37021;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.my-modal .topics-modal__down .block-btn {
  margin-bottom: 8px;
}

.video-modal {
  max-width: 976px;
}

.modal-form__wrap {
  margin-bottom: 22px;
}

.tab {
  display: none;
}

.tab__wrap-center {
  max-width: 77.21%;
  margin: 0 auto 32px;
}

@media (max-width: 767px) {
  .tab__wrap-center {
    max-width: inherit;
  }
}

.tab__wrap-center:last-child {
  margin: 0 auto 62px;
}

.tab__wrap-center .link-more,
.tab__wrap-center .link-row {
  margin-bottom: 38px;
  min-height: 48px;
}

.tab__wrap-center .block-btn {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tab__wrap-center .field,
.tab__wrap-center .textarea {
  margin-bottom: 26px;
}

.tab__wrap-check {
  margin-bottom: 32px;
}

.tab__wrap-check .check-form {
  margin-bottom: 14px;
}

.tab__wrap-check .check-form:last-child {
  margin-bottom: 0;
}

.tab .block-btn {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tab .block-btn:first-of-type {
  margin-bottom: 30px;
}

.tab .block-btn:last-child {
  margin-bottom: 0;
}

.tab.is-active {
  display: block;
}

.tabs-nav__item {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .sticky .page-header__down {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 80;
    background: #fff;
    -webkit-box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
    -moz-box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
    box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
  }
}

@media (min-width: 1024px) and (max-width: 1360px) {
  .sticky .page-header__down .logo-two {
    margin-right: 30px;
  }

  .sticky .page-header__down .navigation__list {
    margin: 0 -16px;
  }

  .sticky .page-header__down .navigation__item {
    padding: 0 16px;
  }
}

@media (min-width: 1024px) {
  .sticky .logo-two {
    width: 272px;
    opacity: 1;
    margin-right: 60px;
  }

  .sticky .navigation__link {
    color: #3a4558;
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px);
  }

  .sticky .search-block__text {
    color: #3a4558;
    -webkit-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }

  .sticky .search-block:hover .search-block__text {
    color: #fff;
  }

  .sticky .search-line {
    padding-top: 0;
  }

  .sticky .search-line__flex input {
    border: 1px solid #d8dade;
    -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .sticky .search-line__close span,
  .sticky .search-line__text {
    color: #3a4558;
  }

  .sticky .nav-menu {
    -webkit-box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
    -moz-box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
    box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
  }
}

@media (max-width: 1359px) {
  .post-news__wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
  }

  .post-news__wrapper--change {
    grid-template-columns: 288px 1fr;
    grid-gap: 32px;
  }

  .wrap-link {
    padding: 0 16px;
    margin-right: 0;
    max-width: inherit;
    position: relative;
  }

  .wrap-link::before {
    content: "";
    position: absolute;
    left: 0;
    top: -webkit-calc(50% - 16px);
    top: -moz-calc(50% - 16px);
    top: calc(50% - 16px);
    height: 32px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    right: auto;
    bottom: auto;
  }

  .wrap-link:first-child::before {
    display: none;
  }

  .wrap-link__text {
    display: none;
  }

  .wrap-link__wrap-icon {
    margin: 0;
  }

  .marquee-line__row {
    padding: 12px 0 20px;
  }

  .line-contacts__wrap {
    display: grid;
    grid-template-columns: 28px 1fr;
    -webkit-column-gap: 27px;
    -moz-column-gap: 27px;
    column-gap: 27px;
    grid-template-areas: "img text" "img tel";
  }

  .line-contacts .sm-icon {
    grid-area: img;
    margin: 0;
  }

  .line-contacts .sm-text {
    grid-area: text;
  }

  .line-contacts .tel {
    grid-area: tel;
  }

  .first-screen-inner__container {
    min-height: 536px;
    padding: 160px 0 90px;
  }

  .page-grid__sidebar {
    max-width: 336px;
  }

  .link-block {
    padding: 0 24px;
  }

  .link-block__item--active .link-block__text {
    -webkit-transform: translateX(8px);
    -moz-transform: translateX(8px);
    -ms-transform: translateX(8px);
    -o-transform: translateX(8px);
    transform: translateX(8px);
  }

  .block-info__wrap-text {
    min-height: 352px;
  }

  .block-info__caption {
    max-width: 550px;
  }

  .grid-block {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1350px) {
  .page-header__wrap {
    display: block;
  }

  .page-header__tel {
    line-height: normal;
  }
}

@media (max-width: 1200px) {
  .grid-posts__wrapper .post-block {
    width: 50%;
  }

  .grid-posts__wrapper .post-block:first-child,
  .grid-posts__wrapper .post-block:nth-child(2),
  .grid-posts__wrapper .post-block:nth-child(3) {
    width: 50%;
  }

  .page-footer__down {
    padding-top: 30px;
  }

  .page-grid__wrapper {
    display: block;
  }

  .page-grid__sidebar {
    max-width: inherit;
    margin-bottom: 35px;
  }

  .page-grid__content {
    /* padding-top: 0; */
  }

  .link-block {
    margin-top: -36.5px;
    border: 1px solid #d8dade;
    padding: 0 0 12px;
  }

  .link-block__up {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px 24px 12px;
  }

  .link-block__caption {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #3a4558;
  }

  .link-block__wrap-arrow {
    overflow: hidden;
    width: 18px;
    height: 10px;
    position: relative;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
      -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
  }

  .link-block__wrap-arrow svg {
    stroke: #e37021;
    width: 40px;
    height: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .link-block__drop-down {
    -webkit-transition: max-height 0.2s ease-out;
    -o-transition: max-height 0.2s ease-out;
    -moz-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
    max-height: 0;
    overflow: hidden;
    padding: 0 24px;
  }

  .link-block__list {
    border-top: 1px solid rgba(58, 69, 88, 0.2);
    margin-top: 12px;
  }

  .link-back,
  .link-block__item--active {
    display: none;
  }

  .link-block__up.active .link-block__wrap-arrow {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .first-screen-inner__container {
    min-height: 480px;
  }

  .title-page {
    font-size: 40px;
    line-height: 48px;
  }

  .link-row__wrap-arrow {
    margin-left: 21px;
  }

  .leadership {
    margin-top: 0;
  }

  .marquee-line__text {
    font-size: 24px;
    line-height: 32px;
  }

  .topics {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 29px;
  }

  .topics__wrap-text {
    width: 100%;
  }

  .topics__title {
    display: none;
  }

  .topics__wrapper {
    margin-bottom: 0;
  }

  .topics__down {
    margin-bottom: 8px;
    width: 31.206%;
    min-width: 224px;
    margin-left: 8px;
  }

  .topic-line {
    display: none;
  }

  .topic-line--active {
    display: inline-block;
  }
}

@media (max-width: 1023px) {
  .container {
    max-width: 100%;
    padding: 0 16px;
  }

  .page-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    left: 16px;
    right: 16px;
    width: auto;
  }

  .page-header .container {
    max-width: inherit;
    margin: 0;
    width: auto;
    padding: 0;
    position: static;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .page-header__up .page-header__flex {
    border: 0;
    padding: 9px 0 14px;
  }

  .page-header,
  .page-header__left,
  .page-header__wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .page-header__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .page-header__wrap:last-child {
    margin-bottom: 0;
  }

  .page-header__text {
    max-width: 110px;
    white-space: pre-wrap;
    margin-bottom: 0;
  }

  .page-header__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-left: 5px;
  }

  .page-header .circle-hint {
    margin-right: 0;
    margin-left: 8px;
  }

  .page-header__right {
    display: none;
  }

  .header-inner .page-header {
    padding: 0 16px;
  }

  .page-header.sticky {
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 16px;
    -webkit-box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
    -moz-box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
    box-shadow: 0 2px 12px rgba(1, 31, 84, 0.2);
  }

  .page-header.sticky .page-header__flex {
    padding: 15px 0;
  }

  .page-header.sticky .page-header__down {
    padding: 0;
  }

  .page-header.sticky .page-header__tel,
  .page-header.sticky .page-header__text {
    color: #3a4558;
  }

  .navigation,
  .page-header.sticky .logo {
    display: none;
  }

  .page-header.sticky .logo--inner-mob {
    display: block;
  }

  .page-header.sticky .burger,
  .page-header.sticky .search-block,
  .page-header.sticky .search-line__close {
    border-color: #d8dade;
  }

  .page-header.sticky .search-block__icon {
    stroke: #e37021;
  }

  .page-header.sticky .burger__line {
    background-color: #e37021;
  }

  .page-header.sticky .search-line {
    padding: 0 16px;
  }

  .page-header.sticky .search-line__flex input {
    border: 1px solid #d8dade;
    -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .search-block {
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .search-block__icon {
    margin: 0;
    stroke: #fff;
  }
}

@media (max-width: 1023px) and (max-width: 767px) {
  .search-block__icon {
    stroke: #e37021;
  }
}

@media (max-width: 1023px) {
  .search-block__text {
    display: none;
  }

  .burger {
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 14px 12px;
    margin-left: 16px;
  }

  .burger__line {
    background-color: #fff;
    height: 2px;
    width: 100%;
  }
}

@media (max-width: 1023px) and (max-width: 767px) {
  .burger__line {
    background-color: #e37021;
  }
}

@media (max-width: 1023px) {
  .burger__line:first-child {
    max-width: 20px;
  }

  .burger__line:last-child {
    max-width: 16px;
  }

  .mob-menu,
  .mob-menu-up__flex {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .mob-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: #0061aa;
    z-index: 100;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: auto;
  }

  .mob-menu.open-menu {
    left: 0;
  }

  .mob-menu-up__flex {
    border-bottom: 1px solid rgba(216, 218, 222, 0.2);
    padding: 9px 0 13px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mob-menu-up__flex,
  .mob-menu-up__left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mob-menu-up__left .logo {
    max-width: 272px;
    margin-right: 24px;
  }
}

@media (max-width: 1023px) and (max-width: 575px) {
  .mob-menu-up__left .logo {
    max-width: 224px;
  }
}

@media (max-width: 1023px) {
  .mob-menu-up__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 11px;
  }

  .mob-menu-up__wrap:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1023px) and (max-width: 575px) {
  .mob-menu-up__wrap {
    display: none;
  }
}

@media (max-width: 1023px) {
  .mob-menu-up__text {
    display: block;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    font-family: "Oswald", sans-serif;
    max-width: 110px;
  }

  .mob-menu-up__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-left: 5px;
  }

  .mob-menu-up .circle-hint {
    margin-left: 8px;
  }

  .mob-menu-up__tel {
    display: block;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
  }

  .close-menu {
    width: 48px;
    height: 48px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
  }

  .close-menu::after,
  .close-menu::before {
    content: "";
    position: absolute;
    left: -webkit-calc(50% - 1px);
    left: -moz-calc(50% - 1px);
    left: calc(50% - 1px);
    top: -webkit-calc(50% - 16px);
    top: -moz-calc(50% - 16px);
    top: calc(50% - 16px);
    width: 2px;
    height: 32px;
    background-color: #e37021;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .close-menu::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mob-menu-middle {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .mob-menu-middle .container,
  .mob-menu-middle__wrapper-scroll {
    height: 100%;
  }

  .mob-menu-middle__container {
    width: 100%;
    height: 100%;
  }

  .mob-menu-middle__scroll-container {
    width: 100%;
    height: 100%;
    padding: 50px 32px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mob-menu-middle__nav {
    width: 100%;
  }

  .mob-menu-middle__item {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .mob-menu-middle__item:last-child .mob-menu-middle__flex {
    border-bottom: none;
  }

  .mob-menu-middle__row {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0 32px;
  }

  .mob-menu-middle__flex {
    border-bottom: 1px solid rgba(216, 218, 222, 0.2);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px 0;
  }

  .mob-menu-middle__caption,
  .mob-menu-middle__wrap-arrow {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .mob-menu-middle__caption {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
  }

  .mob-menu-middle__wrap-arrow {
    width: 24px;
    height: 10px;
    overflow: hidden;
    position: relative;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .mob-menu-middle__arrow {
    width: 40px;
    height: 10px;
    stroke: #e37021;
    position: absolute;
    right: 0;
    top: 0;
  }

  .mob-menu-middle__item.open-accordion {
    background-color: #fff;
  }

  .mob-menu-middle__item.open-accordion .mob-menu-middle__flex {
    border-color: rgba(58, 69, 88, 0.2);
  }

  .mob-menu-middle__item.open-accordion .mob-menu-middle__caption {
    color: #0061aa;
  }

  .mob-menu-middle__row.active .mob-menu-middle__wrap-arrow {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .mob-menu-drop {
    -webkit-transition: max-height 0.2s ease-out;
    -o-transition: max-height 0.2s ease-out;
    -moz-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
    max-height: 0;
    overflow: hidden;
  }

  .mob-menu-drop__wrapper {
    padding-top: 15px;
  }

  .mob-menu-drop__block {
    overflow: hidden;
  }

  .mob-menu-drop__list {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 32px;
    -moz-column-gap: 32px;
    column-gap: 32px;
    margin: -1px 0 0;
    padding: 0 32px 24px;
  }
}

@media (max-width: 1023px) and (max-width: 767px) {
  .mob-menu-drop__list {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }
}

@media (max-width: 1023px) {
  .mob-menu-drop__item {
    text-align: start;
    border-top: 1px dotted rgba(58, 69, 88, 0.2);
    padding: 11.5px 0;
    position: relative;
  }

  .mob-menu-drop__item::before {
    content: "";
    position: absolute;
    left: -8px;
    right: -8px;
    top: 0;
    bottom: 0;
    background-color: #0061aa;
    opacity: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  .mob-menu-drop__item--active::before {
    opacity: 1;
  }

  .mob-menu-drop__item--active .mob-menu-drop__text {
    color: #fff;
    -webkit-transform: translateX(16px);
    -moz-transform: translateX(16px);
    -ms-transform: translateX(16px);
    -o-transform: translateX(16px);
    transform: translateX(16px);
  }

  .mob-menu-drop__item--active .mob-menu-drop__line {
    opacity: 0;
  }

  .mob-menu-drop__link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .mob-menu-drop__line,
  .mob-menu-drop__text {
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .mob-menu-drop__text {
    font-family: "Oswald", sans-serif;
    font-size: 16px;
    line-height: normal;
    color: #3a4558;
  }

  .mob-menu-drop__line {
    width: 16px;
    min-width: 16px;
    margin-left: 15px;
    height: 2px;
    background-color: #e37021;
    margin-top: 12px;
  }

  .mob-menu-drop__item--active,
  .mob-menu-drop__item--active + .mob-menu-drop__item {
    border-top-color: transparent;
  }

  .mob-menu-down__wrap-text {
    display: none;
  }

  .mob-menu-down__down {
    background-color: #e37021;
    min-height: 92px;
  }

  .mob-menu-down__wrapper {
    padding: 34px 64px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
  }
}

@media (max-width: 1023px) and (max-width: 767px) {
  .mob-menu-down__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 1023px) {
  .mob-menu-down__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mob-menu-down__icon {
    stroke: #fff;
    margin-right: 17px;
  }

  .mob-menu-down__text {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
  }

  .first-screen__img {
    max-width: 84.2%;
  }

  .grid-posts {
    margin-bottom: 10px;
  }

  .page-footer {
    padding: 30px 0;
  }

  .page-footer__flex .footer-link-kompany {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-footer__flex .footer-wrap-link,
  .page-footer__flex .social {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .page-footer__flex .social {
    margin-bottom: 0;
    margin-right: 40px;
    width: auto;
  }

  .page-footer__flex .footer-wrap-link {
    width: 100%;
    margin-top: 20px;
  }

  .page-footer__block-text {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -moz-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-top: 20px;
  }

  .page-footer__text {
    text-align: left;
    margin-right: 5px;
  }

  .marquee-line,
  .news-sect {
    margin-bottom: 40px;
  }

  .footer-nav-list__link {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }

  .footer-nav-list__wrap-arrow {
    display: none;
  }

  .hiden {
    padding-right: 0;
  }

  .post-block__image-hover,
  .search-line__close span {
    display: none;
  }

  .post-block:hover .post-block__caption,
  .post-block:hover .post-block__text {
    color: #3a4558;
  }

  .block-btn:hover .block-btn__wrap-svg {
    width: 14px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    margin-right: 0;
  }

  .first-screen-inner__container {
    min-height: 400px;
    padding: 100px 0 60px;
  }

  .block-info {
    margin-bottom: 48px;
  }

  .block-info__wrap-text {
    min-height: 272px;
  }

  .block-info__caption,
  .text-line h3 {
    font-size: 32px;
    line-height: 40px;
  }

  .block-info__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .page-grid__content {
    padding-bottom: 40px;
  }

  .vacancies-block {
    padding: 24px;
  }

  .page-header__up {
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .page-header__container {
    position: relative;
  }

  .page-header__down {
    position: static;
  }

  .page-header.hide-line .page-header__up {
    opacity: 0;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  .search-line {
    padding-top: 0;
  }

  .search-line__close {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  .search-line__text {
    display: none;
  }

  .search-line__flex button {
    padding: 8px 15px;
  }

  .page-header--change .burger,
  .page-header--change .search-block {
    border-color: #d8dade;
  }

  .header-inner--change {
    position: static;
    border-bottom: 1px solid #d8dade;
  }

  .header-inner--change .page-header {
    padding: 0;
  }

  .header-inner--change .page-header--change {
    position: relative;
    left: 0;
    top: 0;
    bottom: auto;
    right: 0;
    margin: 0 16px;
    border-color: transparent;
  }

  .header-inner--change .page-header--change .search-line {
    padding-left: 0;
    padding-right: 0;
  }

  .header-inner--change .page-header--change .search-line__close {
    border-color: #d8dade;
  }

  .header-inner--change .page-header--change.sticky .search-line {
    padding: 0 16px;
  }

  .header-inner--change .page-header.sticky {
    position: fixed;
    margin: 0;
    padding: 0 16px;
  }

  .header-inner--change .search-block__icon {
    stroke: #e37021;
  }

  .header-inner--change .burger__line {
    background-color: #e37021;
  }
}

@media (max-width: 767px) {
  .page-header__wrap {
    display: none;
  }

  .header-inner--offset {
    padding-top: 79px;
  }

  .marquee-line {
    margin-bottom: 30px;
  }

  .mob-menu .container {
    padding: 0 16px;
  }

  .mob-menu-middle .container {
    max-width: 100%;
    padding: 0;
  }

  .mob-menu-middle__scroll-container {
    padding: 25px 0;
  }

  .mob-menu-middle__item {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .mob-menu-middle__row {
    padding: 0;
  }

  .mob-menu-middle__flex {
    max-width: 100%;
    padding: 24px 16px;
  }

  .mob-menu-drop__list {
    padding: 0 16px 24px;
    max-width: 100%;
    margin: -1px auto 0;
  }

  .mob-menu-down__wrapper {
    padding: 34px 0;
    margin: 0;
  }

  .mob-menu-down__up {
    padding-bottom: 27px;
  }

  .mob-menu-down__wrap-text {
    display: block;
    margin-bottom: 20px;
  }

  .mob-menu-down__wrap-text:last-child {
    margin-bottom: 0;
  }

  .mob-menu-down__text-info {
    font-family: "Oswald", sans-serif;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    display: block;
  }

  .mob-menu-down__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mob-menu-down .circle-hint {
    margin-right: 8px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin-top: 3px;
  }

  .mob-menu-down__tel {
    font-family: "Oswald", sans-serif;
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .first-screen__img {
    width: 100%;
    max-width: inherit;
  }

  .line-contacts {
    padding: 15px 0;
  }

  .line-contacts__wrapper {
    grid-gap: 14px;
  }

  .sm-text {
    margin: 0;
  }

  .tel {
    font-size: 24px;
    line-height: 32px;
  }

  .header-inner .page-header {
    position: relative;
    left: 0;
    right: 0;
  }

  .header-inner .search-line {
    padding: 0 16px;
  }

  .header-inner .search-line__flex input {
    border: 1px solid #d8dade;
    -webkit-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .header-inner .page-header.sticky {
    position: fixed;
  }

  .header-inner .logo {
    display: none;
    max-width: 220px;
  }

  .header-inner .logo--inner-mob {
    display: block;
  }

  .header-inner .burger,
  .header-inner .search-block,
  .header-inner .search-line__close {
    border-color: #d8dade;
  }

  .header-inner .first-screen-inner__bg-image {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
  }

  .header-inner .first-screen-inner__bg-image::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(1, 31, 84, 0.5)),
      to(rgba(1, 31, 84, 0.5))
    );
    background: -webkit-linear-gradient(
      bottom,
      rgba(1, 31, 84, 0.5),
      rgba(1, 31, 84, 0.5)
    );
    background: -moz-linear-gradient(
      bottom,
      rgba(1, 31, 84, 0.5),
      rgba(1, 31, 84, 0.5)
    );
    background: -o-linear-gradient(
      bottom,
      rgba(1, 31, 84, 0.5),
      rgba(1, 31, 84, 0.5)
    );
    background: linear-gradient(
      0deg,
      rgba(1, 31, 84, 0.5),
      rgba(1, 31, 84, 0.5)
    );
    z-index: 2;
  }

  .header-inner .first-screen-inner__bg-image img {
    min-height: 176px;
  }

  .header-inner .first-screen-inner::before,
  .mob-menu-up__wrap {
    display: none;
  }

  .header-inner .first-screen-inner__container {
    padding: 24px 0;
    min-height: inherit;
  }

  .header-inner .first-screen-inner__up {
    overflow: auto;
    white-space: nowrap;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .header-inner .tags {
    background-color: #0061aa;
  }

  .header-inner .tags:last-child {
    background-color: rgba(58, 69, 88, 0.45);
    margin-right: 0;
  }

  .header-inner .title-page {
    color: #3a4558;
  }

  .mob-menu-up__left .logo {
    max-width: 224px;
    display: block;
  }

  .link-block {
    margin-top: 0;
    padding-bottom: 8px;
  }

  .link-block__up {
    padding: 16px 16px 8px;
  }

  .link-block__drop-down {
    padding: 0 16px;
  }

  .block-info {
    margin-bottom: 32px;
  }

  .block-info__wrap-text {
    padding: 24px;
    min-height: 240px;
  }

  .positions-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .positions-post__img {
    max-width: 100%;
  }

  .grid-block {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 35px;
  }

  .link-hh {
    padding: 12px 16px;
  }

  .link-hh__img {
    margin-right: 18px;
  }

  .link-hh__text {
    font-size: 16px;
    line-height: 24px;
  }

  .vacancies-block__caption {
    font-size: 18px;
    line-height: 24px;
  }

  .topics {
    display: block;
  }

  .topics__up {
    width: auto;
    margin-bottom: 16px;
  }

  .topics__down {
    width: 100%;
    min-width: inherit;
    margin: 0;
  }

  .post-grid-line {
    grid-gap: 32px;
  }

  .post-news__wrapper--change {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .topics-modal__flex {
    margin-bottom: 22px;
  }

  .topics-modal__flex .block-btn {
    min-width: 48px;
  }

  .topics-modal__flex .block-btn__svg {
    margin-left: 0;
  }

  .my-modal .topics-modal__field {
    width: auto;
    -webkit-flex-basis: 1px;
    -ms-flex-preferred-size: 1px;
    flex-basis: 1px;
  }

  .my-modal .field,
  .topics-modal__wrap-active {
    margin-bottom: 16px;
  }

  .my-modal__close {
    right: 7px;
    top: 7px;
  }

  .my-modal__up,
  .topics-modal__scroll-wrap {
    margin-bottom: 24px;
  }

  .topics-modal__grid {
    max-height: 306px;
  }

  .my-modal .title {
    margin-bottom: 23px;
  }

  .my-modal__up .title {
    margin-bottom: 0;
  }

  .contact-information__up {
    display: grid;
    grid-template-areas: "icon text" "icon tel";
    grid-template-columns: 36px 1fr;
    -webkit-column-gap: 43px;
    -moz-column-gap: 43px;
    column-gap: 43px;
    margin-bottom: 30px;
  }

  .contact-information__icon {
    grid-area: icon;
  }

  .contact-information__text {
    grid-area: text;
  }

  .contact-information__tel {
    grid-area: tel;
  }
}

@media (max-width: 575px) {
  .page-header__down {
    padding: 0;
  }

  .search-block {
    margin-left: 0;
  }

  .footer-link-kompany__link::before,
  .mob-menu-up__wrap {
    display: none;
  }

  .mob-menu-up__flex {
    padding: 19px 0;
  }

  .close-menu {
    width: 40px;
    height: 40px;
  }

  .mob-menu-middle__flex {
    padding: 12px 16px;
  }

  .mob-menu-middle__caption {
    font-size: 18px;
    line-height: 24px;
  }

  .mob-menu-drop__text {
    font-size: 16px;
    line-height: normal;
  }

  .mob-menu-down__wrapper {
    grid-gap: 24px;
    padding: 25px 0;
  }

  .mob-menu-down__wrap-icon {
    margin-right: 17px;
    min-width: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .logo,
  .mob-menu-down__icon {
    margin-right: 0;
  }

  .logo {
    max-width: 224px;
    width: 100%;
  }

  .burger,
  .search-block {
    width: 40px;
    height: 40px;
  }

  .burger {
    padding: 10px 8px;
    margin-left: 8px;
  }

  .news-sect__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 32px;
  }

  .post-news__caption {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .post-news__date {
    margin-bottom: 7px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 26px;
  }

  .marquee-line__row {
    padding: 13px 0 17px;
  }

  .marquee-line__text {
    font-size: 18px;
    line-height: normal;
  }

  .grid-posts__wrapper .post-block {
    width: 100% !important;
    margin-bottom: 16px;
  }

  .post-block__wrapper {
    padding: 24px;
  }

  .post-block__caption {
    font-size: 18px;
    line-height: 24px;
  }

  .line-contacts__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .footer-link-kompany {
    display: grid;
    grid-template-columns: 48px 1fr;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
    grid-template-areas: "logo text1" "logo text2";
  }

  .footer-link-kompany__logo {
    grid-area: logo;
    margin: 0;
  }

  .footer-link-kompany__link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 8px;
    margin-right: 0;
    padding-right: 0;
  }

  .footer-link-kompany__link:first-of-type {
    grid-area: text1;
  }

  .footer-link-kompany__link:last-of-type {
    grid-area: text2;
    border: 0;
    padding-bottom: 0;
  }

  .page-footer__block-text,
  .page-footer__wrapper {
    display: block;
  }

  .page-footer__block:last-child .footer-nav:last-child {
    border: 0;
  }

  .page-footer__text {
    display: inline;
  }

  .footer-nav {
    margin-bottom: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .caption-row {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0 0 18px;
  }

  .caption-row__caption {
    padding: 0;
    margin: 0;
  }

  .caption-row__plus {
    width: 16px;
    height: 16px;
    position: relative;
  }

  .caption-row.active .caption-row__plus::after,
  .caption-row__plus::after,
  .caption-row__plus::before {
    content: "";
    position: absolute;
    height: 2px;
    top: -webkit-calc(50% - 1px);
    top: -moz-calc(50% - 1px);
    top: calc(50% - 1px);
    left: 0;
    right: 0;
    background-color: #e37021;
  }

  .caption-row__plus::after {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .caption-row.active .caption-row__plus::after {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  .accordion-content {
    -webkit-transition: max-height 0.2s ease-out;
    -o-transition: max-height 0.2s ease-out;
    -moz-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
    max-height: 0;
    overflow: hidden;
  }

  .footer-nav-list {
    padding-bottom: 18px;
  }

  .footer-wrap-link {
    display: block;
  }

  .footer-wrap-link__row {
    margin-bottom: 18px;
  }

  .footer-wrap-link__row:last-child,
  .text-line h3:last-child {
    margin-bottom: 0;
  }

  .page-footer__flex .social {
    margin: 0 0 10px;
  }

  .page-footer__down {
    padding-top: 0;
  }

  .block-info__caption,
  .text-line h3,
  .text-line h4,
  .text-line h5 {
    font-size: 24px;
    line-height: 32px;
  }

  .block-info__grid,
  .positions-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .block-info__grid {
    padding-top: 16px;
  }

  .positions-grid {
    grid-gap: 32px;
    margin-bottom: 32px;
  }

  .positions-post__name,
  .positions-post__name-two {
    font-size: 18px;
    line-height: 24px;
  }

  .doc-line__name,
  .text-area p.text-md,
  .text-line p {
    font-size: 18px;
    line-height: 26px;
  }

  .leadership-end-section {
    padding-top: 36px;
  }

  .title-page {
    font-size: 30px;
    line-height: 38px;
  }

  .first-screen-inner__down .block-btn,
  .footer-wrap-link__row::before,
  .search-line__flex button span {
    display: none;
  }

  .search-line__flex button {
    min-width: inherit;
    padding: 8px;
  }

  .page-grid__sidebar {
    margin-bottom: 28px;
  }

  .doc-line {
    margin-bottom: 22px;
  }

  .link-block__caption {
    line-height: normal;
  }

  .row-docs-age__caption {
    margin-bottom: 28px;
  }

  .text-line {
    margin-bottom: 30px;
  }

  .grid-block {
    grid-gap: 16px;
  }

  .vacancies-block {
    padding: 16px;
  }

  .breadcrumbs--up {
    overflow: auto;
  }

  .breadcrumbs--up,
  .breadcrumbs--up .tags,
  .scroll-end {
    white-space: nowrap;
  }

  .scroll-end {
    overflow: auto;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .search-value-block__number {
    margin-right: 14px;
  }

  .search-value-block__caption-mosoblenergotext {
    font-size: 16px;
    line-height: 26px;
  }

  .search-value-block__text-info {
    font-size: 14px;
  }
}

@media (max-width: 359px) {
  .header-inner .logo {
    max-width: 180px;
  }
}

.hh-button.ant-btn-primary span {
  color: #fff !important;
}
